.container {
  border-radius: 1.25rem;
  border-bottom: 0.25rem solid #6bb56a;
  padding: 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  gap: 0.25rem;
  height: 6.25rem;
  width: 100%;
  max-width: 18.74rem;
  /* min-width: 18.74rem; */
  box-shadow: 0 0.188rem 0.188rem #00000040;
}
.container.alert {
  border-color: #f5c54b;
}
.container.expired {
  border-color: #f54b4f;
}
.icon {
  width: 3.75rem;
  height: 3.75rem;
  object-fit: contain;
}
.container.add {
  border: none;
  background: #d8e3e1;
  justify-content: center;
  min-width: 18.75rem;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.container.add .content {
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.title {
  color: #3d3d3d;
  margin: 0;
  margin-bottom: 0.5rem;
  padding: 0;
  font-size: 0.9rem;
  word-wrap: break-word;
}
.container.add .title {
  font-size: 1.25rem;
  color: #8f90a6;
  max-width: none;
  word-wrap: normal;
}
.row {
  margin-bottom: 0.5rem;
  font-size: 0.85rem;
}
.row b {
  color: #6bb56a;
  font-weight: 700;
}
.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}
.actions button {
  all: unset;
  cursor: pointer;
  background: #6bb56a;
  border-radius: 50%;
  width: 1.563rem;
  height: 1.563rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.actions button:disabled {
  cursor: not-allowed;
  background-color: #544c4c40;
}
.actions button img {
  object-fit: contain;
}
.actions button:disabled img {
  filter: grayscale(1);
}
.actions button:first-child {
  background-color: #544c4c40;
}

@media screen and (max-width: 480px) {
  .container {
    margin: 1rem 0;
  }
  .container.add .title {
    font-size: 1.25rem;
  }
  .content {
    width: 100%;
  }
  .content .title {
    max-width: 12.5rem;
  }
}
