.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}
.heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.plate,
.brand {
  z-index: 1;
  background-color: #fff;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 80px;
  border-radius: 8px;
}

.plate {
  border-top: 12px solid #666666;
  font-weight: 500;
  font-size: 1.5rem;
}
.brand img {
  width: 100%;
  height: 80px;
  object-fit: contain;
}

.content {
  border: 1px solid #000;
  border-radius: 8px;
  margin-top: 1rem;
}
