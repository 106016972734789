/* new table */

.table-header {
  display: grid;
  grid-template-columns: 150px 1fr 1fr 135px 135px;
  color: var(--b_grey);

  th {
    span {
      align-self: center;
      vertical-align: middle;
    }
  }
  /* & :first-child {
  } */

  ::before {
    display: none;
  }
}

.table-content {
  max-height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: auto;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e1e5e9;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #c0c4c8;
  }

  & p {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 400px;
    font-size: 2rem;
    color: var(--b_grey);
  }
}

.table-row {
  display: grid;
  grid-template-columns: 150px 1fr 1fr 135px 135px;
  padding: 12px 0px;
  cursor: pointer;

  &.selected {
    background: var(--silver);
  }
  &:not(.selected):hover {
    background: #e1e5e9;
  }
  transition: background 0.24s;

  & .first-line {
    display: flex;
    gap: 8px;
  }
}

.row-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  background: var(--silver);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 1rem;
  padding-right: 2rem;

  animation: opening-animation 0.25s ease forwards;

  & button {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;

    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;

    animation: opacity-animation 0.25s ease forwards;

    @media (width <= 1200px) {
      padding: 0.5rem 1rem;
      font-size: 1rem;
    }
  }
}

@keyframes opening-animation {
  0% {
    max-height: 0;
    overflow: hidden;
  }
  100% {
    max-height: 500px;
  }
}

@keyframes opacity-animation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 880px) {
  .table-header {
    grid-template-columns: 100px 150px 135px 135px 135px;

    & th {
      text-align: center;
    }
  }

  .table-row {
    grid-template-columns: 100px 150px 135px 135px 135px;

    & td {
      max-width: 220px;
      margin: auto 0;
    }
  }

  .table-header,
  .table-content {
    padding-left: 0;
    padding-right: 0;
  }

  .row-actions {
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;
    /* max-width: 92dvw; */
    padding-left: 2rem;
    scrollbar-width: none;

    & button {
      padding: 10px 12px;
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 480px) {
  .table-header {
    grid-template-columns: 100px 150px 135px 135px 135px;

    & th {
      text-align: center;
    }
  }

  .table-row {
    grid-template-columns: 100px 150px 135px 135px 135px;

    & td {
      max-width: 220px;
      margin: auto 0;
    }
  }

  .table-header,
  .table-content {
    padding-left: 0;
    padding-right: 0;
  }

  .row-actions {
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;
    /* max-width: 92dvw; */
    padding-left: 2rem;
    scrollbar-width: none;

    & button {
      padding: 10px 12px;
      font-size: 12px;
    }
  }
}
