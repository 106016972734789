/* reset table css grid-table to flex column */

.filter-scroll {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 2.5rem;
  min-height: 100px;
}

/* Estilizando a barra de rolagem no Chrome, Safari e outros navegadores baseados em WebKit */
.filter-scroll::-webkit-scrollbar {
  width: 2px;
  height: auto;
}

.filter-scroll::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

.filter-scroll::-webkit-scrollbar-thumb {
  background: #e1e5e9;
  border-radius: 8px;
}
.filter-scroll::-webkit-scrollbar-thumb:hover {
  background: #c0c4c8;
}

.filter-table-container {
  table-layout: fixed;

  /* width: 100%; */
}

tr th:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
tr th:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
