.select {
  color: #000000;
  border: none;
  background-color: #eef1f6;
  border-radius: 8px;
  padding: 0.5rem;
  width: 18.75rem;
  height: 40px;

  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .select {
    width: 100%;
  }
}
