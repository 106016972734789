.announcement-banner {
  display: flex;
  align-items: center;
  background: #e8f5e9;
  width: 28.75rem;
  padding: 0.938rem;
  border-radius: 0.5rem;
  gap: 0.5rem;

  position: relative;

  border: none;

  img {
    background-color: white;
    height: 100%;
    width: 60px;
    border-radius: 10px;
  }
}

.announcement-info {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;

  flex: 1;
  overflow: hidden;
}

.title {
  display: flex;
  font-size: 1rem;
  font-weight: 500;

  line-height: 1.067rem;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.description {
  display: block;
  width: 100%;

  font-size: 0.875rem;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;

  line-height: 0.914rem;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #707275;
}

.announcement-button {
  position: absolute;
  bottom: 4px;
  right: 12px;

  border: none;
  background-color: transparent;

  font-size: 0.625rem;
  font-weight: 600;
  line-height: 9.75px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #007a37;
}
