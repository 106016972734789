.heading {
  display: flex;
  gap: 1rem;
  margin: 1rem 2rem 2rem auto;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-height: 60vh; */
}

.describe {
  display: flex;
  align-items: center;
  font-family: 'Inter';
  width: 65%;
}

.describe img {
  width: 100px;
}
.describe p {
  font-size: 1.35rem;
  font-weight: 600;
  width: 80%;
  margin-left: 1rem;
}
.actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* .search-bar {
  position: relative;
} */

@media only screen and (max-width: 480px) {
  .search-bar {
    display: flex;
    width: 100%;
  }

  .heading {
    flex-direction: column;
    padding: 0;
  }
  .describe {
    width: 100%;
    img {
      width: 70px;
    }
  }
  .actions {
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
    button:nth-of-type(2) {
      width: 75%;
    }
  }

  .container {
    div:nth-of-type(1) {
      width: 100%;
    }
    div:nth-of-type(2) > div > button > div {
      width: 100%;
      padding: 0 0.3rem;
    }
  }
}

@media only screen and (max-width: 980px) {
  .heading {
    flex-direction: column;
    padding: 0;
  }
  .describe {
    width: 100%;
    img {
      width: 70px;
    }
  }
  .actions {
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
    button:nth-of-type(2) {
      width: 75%;
    }
  }

  .container {
    div:nth-of-type(1) {
      width: 100%;
    }
    div:nth-of-type(2) > div > button > div {
      width: 100%;
      padding: 0 0.3rem;
    }
  }
}
