.button {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  gap: 0.75rem;

  font-size: 1rem;
  font-weight: bold;
  color: white;

  border: none;
  border-radius: 8px;
  background-color: var(--secondary);

  transition: 120ms;
}

.button:hover {
  filter: brightness(1.1);
  transform: scale(1.01);
  transition: 120ms;
}

.table-header {
  /* header table fixo */
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-row-item.expense:hover,
.table-row-item.expense.collapse,
.row-actions.expense.collapse {
  background-color: #fef6f6;
}

.table-row-item.income:hover,
.table-row-item.income.collapse,
.row-actions.income.collapse {
  background-color: var(--tint_5);
}

.column-price {
  font-weight: bold;
}
.column-price .info {
  display: flex;
  justify-content: center;
  align-content: center;
}
.column-price.income {
  background-color: var(--tint_5);
  color: var(--primary);
}
.column-price.expense {
  background-color: #fef6f6;
  color: var(--error_text);
}

.row-actions {
  position: relative;
  background-color: var(--slate);
  height: 0px;
  transition: height 120ms;
}

.actions {
  padding: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.actions > div {
  display: flex;
  gap: 1rem;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 16px;
  max-width: none;
  transform: translateY(-10px);
  transition: 0s;
}

.row-actions.collapse {
  height: 68px;
}

.row-actions.collapse td {
  padding: 0.75rem;
  position: relative;
}

.row-actions.collapse div {
  opacity: 1;
  visibility: visible;

  transform: translateY(-50%);
  top: 50%;
  transition: 0;
}

.modal-title {
  text-align: start;
  align-self: flex-start;
}

.modal-title.income {
  color: var(--primary);
}
.modal-title.expense {
  color: var(--error-text, #c71d1a);
}

.mobile {
  display: none;
}

.no-transactions {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 500;
  color: #89939e;
  font-size: 1rem;
}

@media screen and (max-width: 1170px) {
  .column-situation {
    display: none;
  }
}

@media screen and (max-width: 870px) {
  .column-situation {
    display: initial;
  }

  .column-price.income,
  .column-price.expense {
    background-color: transparent;
    font-size: 1.25rem;
  }

  .row-actions {
    margin-top: -1.2rem;
  }

  .table-row-item td {
    max-width: none;
    font-weight: 600;
  }

  .table-row-item td:nth-of-type(5)::before {
    content: 'Baixa';
    font-size: 14px;
    font-weight: normal;
  }

  .table-row-item td:nth-of-type(6)::before {
    content: 'Situação';
    font-size: 14px;
    font-weight: normal;
  }

  .table-row-item td:nth-of-type(3)::before {
    content: 'Lançamento';
    font-size: 14px;
    font-weight: normal;
  }

  .table-row-item td:nth-of-type(4)::before {
    content: 'Vencimento';
    font-size: 14px;
    font-weight: normal;
  }

  .table-row-item td:nth-of-type(4),
  .table-row-item td:nth-of-type(5),
  .table-row-item td:nth-of-type(6),
  .table-row-item td:nth-of-type(3) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .row-actions.collapse {
    display: table !important;
    border: 1px solid #89939e;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

@media screen and (max-width: 810px) {
  .button {
    padding: 0.5rem 1rem;

    span {
      display: none;
    }

    &.status {
      span {
        display: inline;
      }

      svg {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .table-row-item td:nth-of-type(1) {
    display: none;
  }
  .column-price .info {
    justify-content: space-between;
    align-items: center;
  }
  .mobile {
    display: block;
  }
  .transaction-code {
    font-size: 16px;
  }
}
