.item {
  list-style-type: none;
  display: flex;
  align-items: center;

  width: 100%;
  font-weight: 500;
  line-height: 3.5rem;
}

.item input {
  padding: 0.5rem 0.25rem;
  border-radius: 8px;
  border: 1px solid var(--primary);
}

.actions {
  display: flex;
  align-items: center;

  margin-left: auto;
}
