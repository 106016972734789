.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container span {
  width: 100%;
  font-weight: 600;
}

.container input {
  height: 100%;
  width: 95%;
  background-color: var(--silver);
  padding: 12px 0;
  outline: none;
  border: none;
  border-radius: 2px;
  font-weight: 600;
}

.container input:focus {
  outline: 1px solid var(--grey);
  border: 1px solid var(--grey);
}

.container input::placeholder {
  text-align: center;
}
.container input:focus,
.container input:hover {
  outline: 1px solid var(--primary);
  border: 1px solid var(--primary);
  background: var(--tint_4);
}

.container input[type='number'] {
  border: none;
  outline: none;
  background: transparent;
}

.container.invalid input {
  background-color: #e5383592;
  outline: 1px solid var(--error);
  border: 1px solid var(--error);
  color: 1px solid var(--error);
}

.container.invalid span {
  color: var(--error) !important;
}

.input-control {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.input-control .top-btn,
.input-control .bottom-btn {
  position: absolute;
}
.input-control .top-btn {
  top: -50%;
}
.input-control .bottom-btn {
  bottom: -50%;
}
/* actions container */
.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 480px) {
  .container {
    background-color: white;
  }
  .container input[type='text'] {
    background-color: transparent;
    outline: none;
    border: none;
    color: var(--black);
    font-size: 1rem;
    font-weight: 500;
  }
  .container input[type='text']:hover {
    outline: none;
    border: none;
    background: transparent;
  }
}
