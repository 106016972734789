.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.form-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

@media screen and (max-width: 480px) {
  .form-row {
    flex-direction: column;
  }
  .input-component {
    width: 100%;
  }
}
