.input-control {
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  z-index: 1;
  position: relative;
}

.input-control input:disabled {
  opacity: 1;
}

.input-control label {
  font-weight: 600;
  font-size: 1.25rem;
  font-family: 'Inter', sans-serif;
}

.input-icon-container {
  position: relative;
  width: 100%;
}
.input-icon {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
}
.input-icon button {
  all: unset;
  cursor: pointer;
}

.input {
  border: 2px solid var(--l_grey);
  border-radius: 8px;
  background-color: var(--silver);
  height: 2.5rem;
  padding: 0.25rem 0.5rem;
  width: 100%;
}

.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 2rem;
  height: 2rem;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  margin: 8px;
  border: 4px solid var(--primary);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
