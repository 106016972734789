.finance-main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  display: flex;
  flex: 1;
  position: relative;
  background-color: #f0f0f0;
}

.absolute-structure {
  position: absolute;
  display: flex;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;
}

.menu-navigation {
  background-color: red;
}

.finance-body {
  flex: 1;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  gap: 1.125rem;

  background-color: #f5f7fa;
}

@media screen and (max-width: 1440px) {
  .finance-body {
    padding-left: calc(5.5rem + 1rem); /*tamanho do menu aside + 24px*/
  }
}

@media (width <= 904px) {
  .finance-body {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 740px) {
  .absolute-structure {
    flex-direction: column;
  }
  .finance-body {
    padding: 1rem;
    align-items: baseline;
  }
}
