.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container img {
  width: 90%;
}

.actions {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
