.modal-content {
  width: 500px;
  animation: fadeIn 0.3s ease-out;
  padding: 1rem;
}

.modal-message {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;

  & button {
    all: unset;
    cursor: pointer;

    display: flex;
    width: 250px;
    height: 40px;
    padding: 0.04rem 0.285rem;
    justify-content: center;
    align-items: center;
    gap: 6px;

    border-radius: 5px;
    background: #43a046;
    transition: all 0.2s ease;
    color: var(--white);
  }

  & button:hover {
    background: var(--secondary);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 480px) {
  .modal-content {
    width: 100%;
  }
}
