.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.title {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.logo {
  width: 60px;
  height: auto;
}

@media screen and (max-width: 480px) {
  .title {
    font-family: 'Bebas Neue';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .logo {
    display: none;
  }
}
