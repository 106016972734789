.search-results-container {
  position: absolute;

  display: flex;
  width: 100%;
  max-height: 34rem;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  overflow-y: auto;

  top: 3.81rem;

  border-radius: 0.5rem;
  border: 1px solid #eef1f6;
  background: #fff;
  box-shadow: 1px 1px 15px 1px gray;

  z-index: 1;
}

.search-result-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
}

.search-results-header {
  /* display: flex;
  flex-direction: row; */

  color: #007a37;

  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  & > h3 {
    color: var(--Primary, #007a37);
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.05rem;
  }
}

.search-header-divisor {
  border: none;

  flex: 1;
  height: 0.0625rem;
  background: linear-gradient(
    90deg,
    rgba(0, 122, 55, 0.5) 0%,
    #007a37 50%,
    rgba(0, 122, 55, 0.5) 100%
  );
  margin-top: 0.313rem;
}

.search-results {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;

  /* width: 1111px;
  height: 552px; */
  border-radius: 8px;
  border: 1px solid #eef1f6;
  box-shadow: 0px 2px 40px 0px #9eb89e29;

  margin-top: 1rem;

  background-color: #ffff;
  color: black;

  & table > tr {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;

    border-radius: 0.25rem;

    background-color: #e1e5e9;
  }
}

@media screen and (max-width: 768px) {
  .search-results-container {
    /* width: 24rem; */
    overflow-x: auto;
  }
}
