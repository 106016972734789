.alert-message-body {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.alert-message-body p {
  margin-bottom: 12px;
}

.alert-message-body b {
  color: #0056b3;
}

.alert-message-body span {
  display: block;
  margin-top: 12px;
  font-size: 14px;
  color: #555;
}

.alert {
  font-size: 14px;
  font-weight: bold;
  color: #d32f2f;
  background-color: #ffebee;
  border: 1px solid #d32f2f;
  border-radius: 4px;
  padding: 8px 12px;
  display: inline-block;
  margin-top: 8px;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
