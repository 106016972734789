.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000040;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* width: 550px; */
  /* padding: 1rem; */
  background: #fff;
  border-radius: 0.625rem;
  overflow: auto;
}

.content.large {
  width: 46.875rem;
}

.content.full {
  width: 100%;
  padding: 0 0.5rem;

  div:nth-of-type(1) > img:nth-of-type(1) {
    width: 2.813rem;
    margin-top: 0.625rem;
  }
}

.header {
  display: flex;
  align-items: flex-start;
  color: var(--primary);
  width: 100%;
  justify-content: space-between;
  padding: 0.2rem;
  position: relative;
  /* height: 170px; */
}

.main {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  /* width: 100%; */
  /* padding: 1rem; */
}

.header hr {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.5rem;
  width: 100%;
  background-color: #007a37;
  z-index: 10;
}
/* .content.large .header {
  padding: 1rem;
} */
.header h2 {
  /* align-self: flex-start; */
  text-align: center;
  flex: 1;
  font-family: 'Bebas Neue';
  font-weight: 400;
  font-size: 2.5rem;
  z-index: 2;
  font-size: 1.5rem;
  /* margin-top: 1.4rem; */
}

.header h2:focus {
  border: none;
  outline: none;
}

.header .pattern {
  position: absolute;
  top: -0.625rem;
  left: -3.125rem;
  z-index: 1;
}
.header button {
  all: unset;
  cursor: pointer;
  /* width: 2.5rem; */
  height: 2.5rem;
  z-index: 10;
}

header .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.body {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  width: 100%;
  /* margin-bottom: 2rem; */
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1rem 1rem 1rem;
  z-index: 1;
}

.imageContainer {
  display: flex;
  width: 100%;

  align-items: center;
  justify-content: center;

  height: 16.9rem;

  a {
    width: 100%;
  }
}

.image {
  display: flex;

  background-color: transparent;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  max-height: 16.9rem;

  width: 100%;
  background-size: cover;
  object-fit: contain;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 0.8rem; */

  width: 100%;

  /* max-height: 260px;
  overflow-y: auto;
  scrollbar-width: thin; */
}

.textContainer > * {
  width: 100%;
  text-align: center;
  text-wrap: wrap;
}

.textContainer h1 {
  font-size: 1.5rem;
}

.textContainer p {
  font-size: 1rem;
}

.message {
  /* display: flex; */
  /* flex-direction: column; */
  max-height: 16.25rem;
  /* max-width: 690px; */
  overflow-y: auto;
  scrollbar-width: thin;
  text-wrap: wrap;
  padding: 0rem 1.875rem 0rem 1.875rem;
  /* width: 100%; */
}

.message > * {
  width: 100%;
  text-align: start;
  text-wrap: wrap;
  margin-bottom: 15px;
  line-height: 22px;
}

.navBarImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* padding-top: 1rem; */
}

.navBarFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 1rem;
}

.icon {
  cursor: pointer;
  border-radius: 0.5rem;
  height: 100%;

  &:hover {
    opacity: 0.5;
    color: #007a37;
    background-color: #f1f1f1;
  }
}

.navBarStepsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0.5rem;
  gap: 0.5rem;
}

.navBarSteps {
  display: flex;
  justify-content: center;
  width: 3.25rem;
  height: 0.75rem;
  border-radius: 1rem;
  background-color: #d9d9d9;

  font-size: 0.625rem;
  font-weight: 500;
}

.navBarImageSteps {
  display: flex;
  justify-content: center;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 1rem;
  background-color: #d9d9d9;
}

.selected {
  background-color: #007a37;

  color: white;
}

.header img {
  width: 2.813rem;
  z-index: 2;
}
.header {
  gap: 0.5rem;
}
.footer {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 1rem auto;
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.loader {
  width: 3rem;
  height: 3rem;
  border: 0.313rem solid var(--primary);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.content::-webkit-scrollbar {
  width: 0.75rem;
}
.content::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* firefox */
.content {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) #f1f1f1;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 880px) {
  .message {
    max-height: 16rem;
  }

  .overlay {
    bottom: 0;
    min-height: 100vh;
  }

  .content,
  .content.large {
    width: 100%;
  }

  .header {
    align-items: flex-start;
  }

  .header h2 {
    font-size: 1.5rem;
  }

  .header img {
    width: 2.188rem;
  }
  .body {
    width: 100%;
  }

  .image {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 480px) {
  .message {
    max-height: 16rem;
  }

  .overlay {
    bottom: 0;
    min-height: 100vh;
  }

  .content,
  .content.large {
    width: 100%;
  }

  .header {
    align-items: flex-start;
  }

  .header h2 {
    font-size: 1.5rem;
  }

  .header img {
    width: 2.188rem;
  }
  .body {
    width: 100%;
  }

  .image {
    width: 100%;
    height: 100%;
  }
}
