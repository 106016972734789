.button {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  gap: 0.5rem;
  border: none;

  font-weight: 600;
  font-size: 1rem;

  transition: background 120ms, color 0s;

  position: relative;
  /* VARIANTS */
  &.default {
    background-color: #263238;
    color: var(--neutral_00, #fff);

    &:hover {
      filter: contrast(120%);
    }
    &:active {
      scale: 0.99;
    }
  }

  &.outline {
    background-color: transparent;
    border: 2px solid #263238;

    &.danger {
      border: 1px solid #e53835;
      color: #e53835;

      &:hover {
        background-color: #c71d1a11;
        filter: brightness(1.1);
      }
    }

    &:hover {
      background: #2632382b;
    }
  }
  /* VARIANT STYLES */
  &.success {
    background-color: var(--primary);
  }

  /* SIZES */
  &.md {
    padding: 0.75rem 2rem;
    height: 44px;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}

.left {
  flex-flow: row-reverse;
}
