.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.container h1 {
  font-weight: normal;
  font-size: 2.2rem;
  text-align: center;
  color: #1e1e1e;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.input-control input:disabled {
  background-color: #f0f0f0;
  /* cor de fundo mais clara para o input desabilitado */
  color: #000000;
  /* cor do texto mais suave */
  cursor: not-allowed;
  /* ícone de cursor de não permitido */
  opacity: 0.6;
  /* opacidade para indicar que o input está desabilitado */
  font-weight: 700;
  /* Deixa o texto em negrito */
}

.input-control input,
.input-control select {
  border: 2px solid #89939e;
  padding: 0.75rem 0.6rem;
  border-radius: 8px;
  width: 100%;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.input-control {
  flex: 1;
  position: relative;
}

.input-control+.street {
  flex: 1.5;
}

.input-control+.uf {
  flex: 0.25;
}

.input-control .icon {
  all: unset;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-30%, -10%);
}

form {
  width: 100%;
}

.form-row {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
}

.code {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 45%;
  margin-top: 1rem;
}

.code>span b {
  color: #737373;
  font-size: 1.25rem;
}

.code>span:nth-child(2) {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
}

.code button {
  cursor: pointer;
  all: unset;
}

.code input {
  border-radius: 8px;
  padding: 0.8rem;
  border: 1px solid #1e1e1e;
  align-self: flex-start;
  text-transform: uppercase;
}

.save-button {
  all: unset;
  cursor: pointer;
  border-radius: 8px;
  background: #007a36;
  font-size: 1.2rem;
  color: #fff;
  padding: 1rem 1.5rem;
  margin: 1rem auto;
  text-align: center;
}

.save-code,
.save-code--action button {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.save-code--button {
  all: unset;
  background-color: #6bb56a;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-code button {
  cursor: pointer;
}

.save-code button span {
  color: #6bb56a;
  font-size: 1.25rem;
  font-weight: 700;
}

@media screen and (max-width: 480px) {
  .section {
    width: 100%;
  }

  .section form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .form-row {
    flex-direction: column;
    align-items: center;
  }

  .input-control {
    flex: 1;
    width: 90%;
  }

  .input-control input {
    font-size: 1rem;
  }

  .code {
    width: 90%;
  }

  .save-button {
    width: 80%;
  }

  .save-code {
    flex-direction: column;
  }
}