.container {
  all: unset;
  cursor: pointer;
  width: 100%;
}

.row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-content: center;
  align-items: center;
  padding: 1rem 0.75rem;
  height: 3.5rem;
  background: #f0f0f0;
  font-size: 1rem;
  padding: 0.5rem 0;
}
.row:nth-child(odd) {
  background: #fff;
}
.row > div {
  width: 100%;
  text-align: center;
  position: relative;
}

.text-left {
  text-align: left;
}

.row.selected {
  color: #fff;
  background: var(--primary);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  transition: color ease 300ms;
}
.row.selected .dropdown .icon {
  transform: rotateX(180deg);
}
.subrow {
  background: var(--primary);
  padding: 1rem 0.75rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  backdrop-filter: blur(5px);
}
.subrow button {
  all: unset;
  background: var(--secondary);
  padding: 0.5rem 0.75rem;
  border-radius: 8px;

  color: #fff;
  font-weight: 500;

  display: flex;
  gap: 0.6rem;
  align-items: center;
  cursor: pointer;
  box-shadow: 1px 2px 1px 0 #00000040;
}

.first-mobile .icon {
  display: none;
}
.dropdown {
  display: flex;
  width: 100%;
  padding-left: 1rem;
}
.dropdown .icon {
  flex: 0.3;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  transition: all ease 300ms;
}
.dropdown span {
  flex: 1;
}
.last {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.last span {
  margin-left: 30%;
}

.status-indicator {
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #f5c54b;
  transition: all ease 300ms;
}
.status-indicator.finished {
  background: #6bb56a;
}
.selected .status-indicator {
  border: 1px solid #fff;
}

.tooltip {
  background: #1e1e1e;
  position: absolute;
  left: 1;
  top: 1;
  color: #fff;
  padding: 0.25rem 0.5rem;
  transition: all ease 200ms;
}

@media screen and (max-width: 480px) {
  .desktop {
    display: none;
  }
  .row {
    grid-template-columns: repeat(3, 1fr);
    padding: 1.5rem 0;
  }
  .first-mobile {
    display: flex;
    width: 100%;
  }
  .first-mobile .icon {
    display: flex;
    margin-left: 0.25rem;
  }
  .first-mobile span {
    flex: 1;
  }
  .last {
    justify-content: space-around;
  }
  .last span {
    margin-left: 0.5rem;
  }
  .subrow {
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;
    width: 100%;
    overflow-x: auto;
  }
  .subrow::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }
  .subrow::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #f0f0f0;
  }
  .subrow button {
    font-size: 0.8rem;
  }
}
