.main {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  background: #ffffff;
  padding: 1rem 3.6% 0 4.7%;
  gap: 1.5rem;
  position: relative;
  padding-bottom: 3rem;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header > strong {
  font-family: 'Inter';
  display: inline-block;
  padding: 0;
  font-size: 3rem;
}

.header > p {
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.headerTop {
  display: none;
}

.headerTop > strong {
  font-family: 'Inter';
  display: inline-block;
  padding: 0;
  font-size: 2.5rem;
}

.headerTop > p {
  margin-top: 0.5rem;
  font-weight: 500;
  font-size: 1.5rem;
}

.wrapper {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 50;
}

.wrapper img {
  width: 50rem;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
}

.mobile {
  display: none;
  width: 100%;
  justify-content: center;
  margin-bottom: 1rem;
}

.inputArea > label,
.inputArea > button,
.inputArea > input,
.input-icon-container > input {
  display: block;
}

.inputArea > label {
  margin: 1.5rem 0;
  font-size: 1.25rem;
  font-weight: 600;
  font-family: 'Inter';
}

.inputArea > input,
.input-icon-container > input {
  align-self: center;
  width: 30rem;
  height: 3rem;
  border: 0;
  background-color: #f5f7fa;
  padding: 1rem 1.5rem;
  border-radius: 6px;
}

.inputArea > input::placeholder,
.input-icon-container > input::placeholder {
  font-weight: 600;
  font-family: 'Inter';
  color: rgba(0, 0, 0, 0.25);
}

.inputArea > button {
  z-index: 5;
  font-family: 'Bebas Neue', sans-serif;
  background-color: #0b5226;
  color: white;
  border: 0;
  width: 30rem;
  align-self: center;
  height: 3rem;
  font-size: 1.5rem;
  border-radius: 7.5px;
}

.input-icon-container {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}

.input-icon-container .icon {
  all: unset;
  cursor: pointer;
  position: absolute;
  top: 55%;
  right: 0;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.checkbox {
  margin-right: 0.5rem;
}

.checkboxText {
  display: inline;
  font-size: 1rem;
}

.cadastro_recuperacao {
  margin: 1rem 0 2rem 0;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;
}
.cadastre_se {
  font-size: 0.9rem;
}

.forgetPassword,
.cadastre_se {
  background-color: transparent;
  border: 0;
}

.forgetPassword > strong {
  margin-bottom: 1rem;
  display: block;
  color: #007a37;
}

.cadastre_se {
  color: #2194f3;
  font-weight: 600;
  text-decoration: underline;
}

.backgroundPatterLogin {
  z-index: 0;
  position: absolute;
  bottom: 0;
  right: 0%;
}

.imagesColumn {
  position: relative;
}

@media screen and (max-width: 1500px) {
  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6rem 0;
  }

  .main > header {
    text-align: center;
    align-self: center;
    margin-bottom: 1rem;
  }

  .wrapper {
    justify-content: center;
  }

  .wrapper img {
    width: 100%;
    align-self: center;
    width: 25rem;
    margin-top: 0;
  }

  .inputArea,
  .input-icon-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
  }

  .inputArea > input,
  .input-icon-container > input {
    width: 100%;
  }

  .input-icon-container > .icon {
    right: 5%;
  }

  .inputArea > button {
    width: 100%;
  }

  .cadastro_recuperacao {
    text-align: center;
  }

  .backgroundPatterLogin {
    height: 20rem;
    right: 20%;
  }
}

@media screen and (min-width: 888px) and (max-width: 1500px) {
  .wrapper img {
    padding-left: 2rem;
    margin-top: -8rem;
    width: 30rem;
  }
}

@media screen and (min-width: 673px) and (max-width: 888px) {
  .wrapper img {
    padding-left: 2rem;
    margin-top: -8rem;
    width: 25rem;
  }
}

@media screen and (max-width: 888px) {
  .backgroundPatterLogin {
    height: 15rem;
    right: 3%;
  }
}

/* Mostra somente o header que está acima da imagem*/
@media screen and (max-width: 672px) {
  .headerTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .header {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .main > header {
    padding-top: 0;
  }

  .main > header > strong {
    display: inline-block;
    padding: 0;
    font-size: 1.8rem;
  }

  .main > header > p {
    margin-top: 0.5rem;
    font-weight: 600;
    font-size: 1.2rem;
  }

  .wrapper img {
    align-self: center;
    width: 18rem;
    margin-top: 0;
  }

  .inputArea {
    justify-content: center;
    text-align: center;
  }

  .inputArea > label {
    font-size: 1rem;
  }

  .inputArea > input,
  .input-icon-container > input {
    width: 20rem;
  }

  .inputArea > button {
    width: 20rem;
  }
}

@media screen and (max-width: 800px) {
  .main {
    padding: 6rem 0;
  }
}

/* loading animation  */
.login-btn {
  display: flex !important;
  justify-content: center;
  align-items: center;
  transition: background-color 100ms linear, transform 100ms linear;
}

.login-btn:hover {
  background-color: #007a37;
  transform: scale(1.1);
}

.wrong {
  background: #f54b4f !important;
}

.loader {
  height: 1.5rem;
  width: 1.5rem;
  border: 3px solid #b3b3b3;
  border-radius: 50%;
  border-right-color: #e3e3e3;
  animation: spin 1s ease infinite;
}

.access-type-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
}
.access-type-container span {
  display: flex;
  align-items: center;
}

.access-type-selector {
  display: flex;
  gap: 1rem;
}

.access-type-selector button {
  all: unset;
  cursor: pointer;

  width: 150px;
  padding: 0.25rem 0.5rem;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--primary) solid 1px;

  color: var(--primary);
  font-weight: 500;
  transition: background ease 0.25s;
}

.access-type-selector button.selected {
  background-color: var(--primary);
  color: var(--white);
  font-weight: 600;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 480px) {
  .row button {
    display: none;
  }
  .mobile {
    display: flex;
  }

  .header > strong {
    font-size: 2.5rem;
  }
  .headerTop > strong {
    font-size: 3rem;
  }
  .cadastro_recuperacao {
    max-width: 300px;
  }

  .access-type-container {
    flex-direction: column;
    padding-top: 1rem;
  }
}
