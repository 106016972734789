.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 1rem;
  align-items: center;
  min-height: 40vh;
  min-width: 40vw;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem 5%;
}

.bigText {
  font-size: 2rem;
}

.accessBtn {
  background: #6bb56a;
  color: white;
  border: var(--black);
  border-radius: 15px;
  width: 50%;
  padding: 0.75rem 0.5rem;
}

.forgot {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.forgot-text {
  color: var(--primary);
  text-decoration: underline;
  font-size: 0.9rem;
}

.input-control {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 80%;
  position: relative;
}

.input-control input {
  font-size: 16px;
}

.input-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
}

.input-icon button {
  all: unset;
}

.password {
  color: #7c7c7c;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 0.5rem;
  width: 80%;
  margin-right: 15px;
  height: 2.5rem;
  padding: 1rem 0.5rem;
  font-size: 1rem;
}

.changeAccessBtn {
  all: unset;

  color: #1e1e1e;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.changeAccessBtn span {
  color: #6bb56a;
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 425px) {
  .container {
    width: 95%;
  }
  .changeAccessBtn {
    gap: 0;
  }
  .input-icon {
    right: -10px;
  }
}
