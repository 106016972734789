.container {
  display: flex;
  flex-direction: column;
  width: 96%;
  border-radius: 20px;
  background: #ffffff;
  margin-top: 2rem;
}

.head {
  display: flex;
  width: 100%;

  align-items: center;
  justify-content: space-between;
  padding-right: 2rem;
  margin: 1rem 0;
  gap: 1.5rem;
}

@media screen and (max-width: 480px) {
  .desktop {
    display: none;
  }
}
