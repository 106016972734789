.displayNone {
  display: none;
}

.container {
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  background-color: #ffffff;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 6rem 9%;
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.layer {
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0;
  width: 25rem;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
}

.containerCall {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.containerCall h1 {
  font-family: 'Inter';
  font-size: 2.2rem;
  margin-bottom: 1rem;
}

.containerCall p {
  font-size: 1.2rem;
  font-family: 'Inter';
  max-width: 350px;
}

.containerCall img {
  margin-top: 4rem;
  width: 100%;
  position: relative;
  z-index: 1;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  position: relative;
  z-index: 10;
}

.form h2 {
  font-weight: 600;
  font-size: 1.25rem;
}

.form div {
  width: 100%;
}

.formInput {
  padding-left: 2rem;
  width: 100%;
  height: 2.5rem;
  border: none;
  background-color: #f5f7fa;
  border-radius: 8px;
  margin-top: 1rem;
}

.formInput::placeholder {
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 500;
}

.formInputTextArea {
  padding-left: 0.8rem;
  padding-top: 8px;
  width: 100%;
  height: 100px;
  border: none;
  resize: none;
  background-color: #f5f7fa;
  border-radius: 8px;
  margin-top: 1rem;
}

.formBtn {
  width: 100%;
  background-color: #0b5226;
  color: #ffff;
  padding: 0.6rem;
  border-radius: 4px;
  border: none;
  font-size: 1.2rem;
  transition: background-color 100ms linear, transform 100ms linear;
}

.formBtn:hover {
  background-color: #007a37;
  transform: scale(1.1);
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 3rem;
  position: relative;
  z-index: 10;
}

.social div {
  width: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.social a {
  text-decoration: none;
  font-size: 10px;
  color: #242424;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.socialTop {
  display: none;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.socialWrapperTop {
  display: flex;
  background-color: #0b5226;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 180px !important;
  height: 55px !important;
  gap: 5px;
  transition: background-color 100ms linear, transform 100ms linear;
}

.socialWrapperTop:hover {
  background-color: #007a37;
  transform: scale(1.1);
}

.socialWrapperTop a {
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
}

.socialWrapperTop p {
  color: #ffff;
}

.socialMediaLogo {
  height: 2rem;
  margin-right: 0.5rem;
}

@media (min-width: 768px) {
  .form {
    width: 400px;
  }
  .wrapper {
    gap: 10%;
    flex-direction: row;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
  }

  .right-column {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .social {
    display: none;
  }
  .socialTop {
    display: flex;
  }
  .containerCall {
    justify-content: flex-start;
  }
  .layer {
    width: 25rem;
    left: 0;
    height: 25rem;
  }
}

/* Apenas ajustando a distância do header para o corpo, deixando mais bonito na responsividade */
@media screen and (min-width: 768px) and (max-width: 1400px) {
  .wrapper {
    padding-top: 10%;
  }
}

@media screen and (max-width: 768px) {
  .socialMediaLogo {
    margin-right: 0rem;
  }
}
