.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: var(--tint_5);
  border-radius: 8px;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
}
.title {
  font-size: 0.8rem;
  text-align: center;
  width: 100%;
  margin-bottom: 0.5rem;
}

.status-indicator {
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #f5c54b;
}
.status-indicator.finished {
  background: #6bb56a;
}
.row:first-child {
  margin-bottom: 0.5rem;
}
.row {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
  color: #1e1e1e;
}
/* até ser decidido */
@media screen and (max-width: 480px) {
  .container {
    padding-bottom: 1rem;
  }
  .title {
    text-align: start;
    font-weight: 800;
    font-size: 1rem;
  }
  .row {
    font-size: 1rem;
  }
  .container {
    width: 100%;
    margin-bottom: 1rem;
  }
  .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 0 0.5rem;
  }
  .row:first-child {
    margin-bottom: 0;
  }
}
