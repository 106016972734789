.modal-view {
  width: 80vw;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  .row {
    display: grid;
    grid-template-columns: 1fr 110px;
    gap: 1rem;
  }
}

.modal-view-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  font-weight: 500;

  span {
    font-size: 0.75rem;
    color: #89939e;
  }
  strong {
    font-weight: 600;
    width: 100%;
    overflow-wrap: break-word;
  }

  &.empty {
    strong {
      color: #717171;
      font-weight: 500;
      font-size: 1.125rem;
    }
  }
}

.sm {
  font-size: 1.125rem;
}

.lg {
  font-size: 1.25rem;
}

.value {
  overflow: auto;
  strong {
    font-weight: 700;
    overflow-wrap: break-word;
  }
  &.income {
    color: var(--primary);
  }
  &.expense {
    color: var(--error_text, #c71d1a);
  }
}
