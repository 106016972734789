.container {
  display: flex;
  flex-direction: column;
}

.content {
  background-color: var(--white);
  min-height: 75dvh;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  width: 90%;
  margin: 2rem auto 0;
  border-radius: 16px;
}

.section {
  display: flex;
  justify-content: space-between;
  /* position: relative;*/
}

@media screen and (max-width: 480px) {
  .content {
    width: 100% !important;
    padding: 1rem;
  }
}
