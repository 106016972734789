.row {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin: 0.5rem 0.5rem;
  flex-wrap: wrap;
}
.label {
  font-weight: 500;
  font-size: 1rem;
}
.text {
  font-weight: 600;
  font-size: 1rem;
}

@media screen and (min-width: 1440px) {
  .label,
  .text {
    font-size: 1.6rem;
  }
}
