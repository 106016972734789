.navigation-link {
  border: 0;

  display: flex;
  align-items: center;
  padding: 1rem;
  width: 15.75rem;
  gap: 1rem;

  border-radius: 6px;
  background-color: var(--white);

  font-size: 1rem;
  font-weight: bold;
  color: #696b69;

  transition: hover 120ms, width 0.3s ease-out;
}

.navigation-link span {
  opacity: 1;
  visibility: 1;

  transition: opacity 0.7s ease-out, visibility 0.7s ease-out,
    width 0.5s ease-out, position 0.7s;
}

.navigation-link:hover {
  background-color: #f6f6f6;
}

.navigation-link.active {
  background-color: #f0f0f0;
  color: var(--secondary);
}

@media screen and (max-width: 1440px) {
  .navigation-link {
    width: auto;
    transition: width 0.5s ease-out;
  }

  .navigation-link span {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-24px);
    width: 0;

    position: absolute;
    transition: opacity 0s ease-out, visibility 0.1s ease-out,
      width 120ms ease-out, transform 0.2s ease-out;
  }
}

@media screen and (max-width: 740px) {
  .navigation-link:hover span {
  }
}
