/* seções do formulário */

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.content {
  background-color: var(--white);
  padding: 1rem 8rem;
  flex: 1;
  height: 100%;

  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  overflow-y: auto;
}

.change-step-btn {
  all: unset;
  cursor: pointer;

  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--primary, #007a37);
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
    background-color: var(--secondary, #0b5226);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  &:disabled {
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: 0.5;
  }
}

.form-section {
  flex: 1;
  height: 100%;

  & > form {
    height: 100%;
    overflow-y: auto;
    overflow-x: clip;
  }
}

.form {
  width: 50vw;
  margin-inline: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 2.5rem;
  padding-inline: 2rem;

  &::-webkit-scrollbar {
    width: 6px;
    height: auto;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: var(--secondary);
  }

  transform: translateX(50px);

  animation: slide-from-left 0.4s ease-in-out forwards;
}

@keyframes slide-from-left {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.form-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 28px;
  align-self: stretch;
}
.form-column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &.border-bottom {
    border-bottom: 4px solid var(--primary, #007a37);
  }
}

.info {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.total {
    font-weight: 700;
  }
}

.image-input-wrapper {
  display: flex;
  flex-direction: column;
  & > span {
    color: var(--secondary, #0b5226);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.image {
  width: 68px !important;
  height: 68px;

  & > button {
    width: 68px;
    height: 68px;
  }
}

.save-form-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & span {
    text-align: center;
    color: var(--secondary, #0b5226);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  & .save-form--row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 28px;
    align-self: stretch;
  }

  & .save-form--row > button {
    width: 240px;
  }
}
.mobile-actions-container {
  display: none;
}
.cep-uf-city {
  display: grid;
  grid-template-columns: 28% 16% auto;
  grid-template-areas: 'cep uf cidade';
}
.address-row {
  display: grid;
  grid-template-columns: 37% 37% auto;
  grid-template-areas: 'bairro rua numero';
}
.cep {
  grid-area: cep;
}
.uf {
  grid-area: uf;
}
.cidade {
  grid-area: cidade;
}
.bairro {
  grid-area: bairro;
}
.rua {
  grid-area: rua;
}
.numero {
  grid-area: numero;
}

/* componentes do formulário */

.primary-btn,
.second-btn {
  all: unset;
  cursor: pointer;

  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;

  color: #fff;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  flex: 1;
  &:hover {
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
  }
}

.primary-btn {
  background: var(--b_grey, #263238);

  &:hover {
    filter: grayscale(0.1);
  }
}

.second-btn {
  background: var(--primary, #007a37);
  &:hover {
    filter: grayscale(0.1);
  }
}

.input {
  width: 100%;

  & label {
    color: var(--primary, #007a37);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  & div {
    border-radius: 8px;
    border: 2px solid var(--D_Grey, #89939e);
    background: #fff;
  }
}

.textarea {
  width: 100%;
  padding-inline: 0.5rem !important;
  & label {
    color: var(--primary, #007a37);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  & div {
    border-radius: 8px;
    border: 2px solid var(--D_Grey, #89939e);
    background: #fff;
  }

  & textarea {
    height: 50px;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 8px;

  color: var(--black, #000);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  cursor: pointer;

  & > input {
    width: 1rem;
    height: 1rem;
  }
}

.discount-input {
  width: 120px;

  & > input {
    width: 100%;
    height: 100%;
    border: none;
    padding: 8px 12px;

    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: end;

    border: 2px solid transparent;
    border-radius: 8px;
    padding-right: 0;
    border: 2px solid var(--D_Grey, #89939e);
  }
  &:hover > input {
    background: #fff;
  }
}

.expandable-section {
  overflow: visible;
}

@media screen and (max-width: 480px) {
  .change-step-btn {
    display: none;
  }
  .content {
    padding: 0 0 1rem;
  }

  .form-section {
    & > form {
      height: auto;
    }
  }

  .form {
    width: 100vw;
    min-height: 55vh;
  }
  .form-row {
    flex-wrap: wrap;
  }
  .image-input-wrapper {
    max-width: 100vw;
    width: 100%;
    overflow: auto;
  }
  .cep-uf-city {
    grid-template-areas:
      'cep cep uf'
      'cidade cidade cidade';
    grid-template-columns: 30% 10% 1fr;
    gap: 16px;
  }

  .address-row {
    grid-template-areas:
      'bairro bairro'
      'rua numero';
    grid-template-columns: auto 30%;
  }

  .mobile-actions-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-self: stretch;
    padding-inline: 2rem;
    gap: 1rem;
  }

  .services-table-wrapper {
    display: flex;
    max-width: 100vw;
    width: 100%;
    overflow: auto;
  }

  .save-form-container {
    & .save-form--row > button {
      font-size: 12px;
    }
  }
}
