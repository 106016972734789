.row {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.row.column {
  flex-direction: column;
  align-items: start;
}
.label {
  font-size: 1rem;
  color: var(--b_grey);
  font-weight: 700;
  width: 100px;
}
.value {
  font-size: 1rem;
  color: var(--d_grey);
  font-weight: 600;
}
