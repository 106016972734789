.wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
}

.wrapper h2 {
  font-weight: 900;
  color: #599358;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 1rem 0;
  background-color: #ebf6f4;
  border-radius: 1.25rem;
}
.container h5 {
  color: #1e1e1e;
  font-weight: 500;
}

.item {
  width: 100%;
}
.carousel {
  display: flex;
  overflow-x: scroll;
  width: 100%;
}

.carousel::-webkit-scrollbar-track {
  background: transparent;
}

.carousel::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.carousel::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.25rem;
}
.slide {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
  padding: 1rem 0;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
}

.right,
.left {
  all: unset;
  cursor: pointer;
  background-color: #e3efec;
  position: absolute;
  width: 2rem;
  height: 100%;
  top: 0;
  z-index: 1;
}
.left {
  left: 0;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
}
.right {
  right: 0;
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}
@media screen and (min-width: 769px) {
  .list {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .list {
    width: 100%;
    padding: 0.25rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    justify-items: center;
    gap: 0.5rem;
  }
  .right,
  .left {
    display: none;
  }
  .carousel {
    display: none;
  }
}

@media screen and (max-width: 980px) {
  .list {
    width: 100%;
    padding: 0.25rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    justify-items: center;
    gap: 0.5rem;
  }
  .right,
  .left {
    display: none;
  }
  .carousel {
    display: none;
  }
}

@media screen and (max-width: 1400px) {
  .list {
    width: 100%;
    padding: 0.25rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    justify-items: center;
    gap: 0.5rem;
  }
  .right,
  .left {
    display: none;
  }
  .carousel {
    display: none;
  }
}
