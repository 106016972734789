.container {
  display: flex;
  flex-direction: column;
  align-self: stretch;

  border-radius: 8px;
  background: #eef0f2;
  box-shadow: 3px 3px 12px 0px rgba(0, 0, 0, 0.25);
  padding: 24px 16px;
  gap: 20px;
}

.item-info {
  display: grid;
  grid-template-columns: 50px 2fr 1fr 1fr;
  align-items: center;
  /* padding: 24px; */
}

.item-info span {
  color: var(--black);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
  text-align: center;
}
.item-info span:nth-child(1) {
  font-weight: 700;
}
.item-info span:nth-child(2) {
  flex: 2;
}
.item-info span:nth-child(3) {
  text-align: center;
}

.item-info b {
  color: var(--primary);
  font-size: 0.85rem;
  font-style: italic;
}

.product-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  animation: opening-animation 1s ease forwards;
  transition: all 1s;
}

.product-list > div {
  animation: opacity-animation 1s 0.25s ease forwards;
  transition: all 1s;
  opacity: 0;
}

.empty-row {
  display: flex;
  gap: 16px;
  align-items: center;
  color: var(--grey);
  font-weight: 600;
}

@keyframes opening-animation {
  0% {
    max-height: 0;
    overflow: hidden;
  }
  100% {
    max-height: 500px;
  }
}

@keyframes opacity-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
