.aside {
  width: 18%;
  padding-right: 1rem;
  height: 100vh;
}
.aside ul {
  background: var(--white);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-top: 2rem;
  gap: 1.5rem;
}

.option {
  list-style: none;
  font-weight: 800;
  color: var(--black);
}

.option button {
  all: unset;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 85%;
  margin: 0 auto;
}
.option button img {
  fill: var(--black);
}
.option.selected button {
  color: var(--primary);
}
.option.selected button svg path {
  fill: var(--primary);
}

@media screen and (max-width: 768px) {
  .aside {
    position: absolute;
    right: -100%;
    top: 0;
    z-index: 10;
    width: 60%;
    transition: all 0.5s ease-in-out;
  }
  .aside.opened {
    right: 0;
  }
}
