.filter-table-header {
  /* padding: 0.75rem 0.5rem; */
  padding: 0.375rem 0.5rem;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  vertical-align: middle;
  background-color: #e1e5e9;

  /* min-width: 150px; */
  /* display: flex; */
  /* flex-direction: row; */
  /* width: 100%; */

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-left: 0.5rem;
    /* margin-right: 3rem; */
    color: #89939e;
  }
}

/* hide content between cells by creating a white box behind the header */
.filter-table-header::before {
  content: '';
  background: white;
  width: 100%;
  /* height: 40%; */
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
}
