.companies-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.companies-container input {
  font-weight: 600;
}
/* .companies-list {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey);
  border-radius: 4px;
  height: 455px;
  max-height: 455px;
  overflow: auto;
}

.companies-list li {
  list-style: none;
  padding: 0.75rem 0.5rem;
}
.companies-list li label {
  font-family: 'Inter';
  font-weight: 600;
  color: #333;
}

.companies-list li.checked {
  background-color: var(--tint_5);
} */

.search-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding-right: 10px;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--Grey, #717171);
  background: var(--Silver, #f5f7fa);
  box-shadow: 0px 0px 16px 0px rgba(234, 76, 137, 0.06);
  overflow: hidden;
}

.search-input {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px;
  border: transparent;
  background: var(--Silver, #f5f7fa);

  &::placeholder {
    color: #333;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

.search-input--icon {
  display: flex;
  width: 35px;
  height: 35px;
  padding: 9.722px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 19.444px;
  flex-shrink: 0;

  border-radius: 101.111px;
  background: var(--Tint-4, #c8e6c9);
}

.companies-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1 0 0;
  align-self: stretch;
  max-height: 50vh;
  min-height: 50vh;
  overflow: hidden scroll;
  margin-bottom: 1rem;

  border-radius: 6px;
  border: 1px solid var(--Grey, #717171);
  background: var(--Silver, #f5f7fa);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
}

.loader-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.no-results {
  padding-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--B_Grey, #263238);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Personalização para navegadores baseados em WebKit (Chrome, Edge, Safari) */
.companies-list::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem */
}

.companies-list::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor de fundo da trilha */
}

.companies-list::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 4px; /* Deixa a barra arredondada */
  cursor: grab;
}

.companies-list::-webkit-scrollbar-thumb:hover {
  background-color: var(--secondary);
}

.company-option {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 10px;
  align-items: center;
  gap: 10px;

  & label {
    color: #333;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  & input {
    width: 10.857px;
    height: 8px;
    fill: #fff;
    stroke-width: 0.5px;
    stroke: #fff;
  }
}

.company-option.selected {
  background: var(--Tint-5, #e8f5e9);
}

.actions {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;

  & button {
    transition: all 0.2s ease;
    cursor: pointer;
    width: 200px;
    height: 48px;
    flex-shrink: 0;

    color: var(--Black, #000);
    text-align: center;
    font-family: 'Bebas Neue';
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    border-radius: 8px;
    border: 2px solid var(--B_Grey, #263238);
  }

  .back-btn {
    background: var(--white);
  }

  .back-btn:hover {
    background: var(--Black, #000);
    color: var(--white);
  }

  .send-btn {
    background: var(--secondary, #000);
    color: var(--white);
    border-color: transparent;
  }

  .send-btn:hover {
    background: var(--primary, #000);
  }
}

@media only screen and (max-width: 480px) {
  .companies-container {
    height: 100%;
  }
}
