.searchbar {
  border: 1px solid #dedede;
  padding: 0.125rem 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  max-width: 300px;
  width: 100%;
  overflow: hidden auto;
  height: 2.5rem;
  gap: 0.25rem;
}
.searchbar input {
  all: unset;
  width: 100%;
}
.searchbar input::placeholder {
  font-weight: 500;
  color: #7c7c7c;
  font-size: 0.8rem;
}
