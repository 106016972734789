.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80vh;
  background: #fff;
  border-radius: 8px;

  @media screen and (max-width: 768px) {
  }
}

.release-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 2rem 1.6rem;
}

.release-list--header {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.filter-wrapper {
  display: flex;
  gap: 2rem;
}

.reports-label {
  font-size: 1.313rem;
}

.filter-wrapper h1 {
  font-weight: 700;
}

.filter-label {
  color: #b4b3b8;
  font-size: 0.938rem;
}

.filters {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.filter-wrapper--select {
  color: #7c7c7c;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 0.5rem;
  width: 120px;
}

.release-list--content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  overflow-y: auto;
  height: 70vh;
}

.release-list--content::-webkit-scrollbar {
  width: 6px;
}

.release-list--content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.green-button {
  all: unset;
  background: #6bb56a;
  padding: 0.5rem 0.75rem;
  border-radius: 8px;
  color: #fff;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    flex: 1;
  }
}

.report-list--header {
  width: 100%;
  list-style: none;
  padding: 0 0.5rem;

  display: grid;
  grid-template-columns: 1.5fr repeat(4, 1fr) auto;
  align-items: center;
  text-align: center;

  min-width: 600px;
}

.date-filter {
  display: flex;
  align-items: center;
}

.left {
  text-align: left;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  align-items: center;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}

.marginTop {
  margin-top: 0.5rem;
}

.wrapSpan {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 2rem;

  @media only screen and (max-width: 425px) {
    width: 100%;
    height: 1rem;
  }
}
