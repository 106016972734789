.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  min-height: 100vh;
  background: #f0f0f0;
  padding: 0;
  margin: 0;
}
.content {
  display: flex;
  max-width: 100vw;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}
