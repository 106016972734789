.actions {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.actions button {
  width: 40%;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.permissionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.permissionsList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.permissionsList li {
  list-style: none;
}
