.container {
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
}

.container h1 {
  color: var(--black);
  text-align: center;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.quotation-list-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 0.5rem;
}

.subtotal {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background: var(--tint_5);
  font-family: 'Inter';
  font-size: 1rem;
  line-height: 4rem;
  border-radius: 4px;
}

.subtotal-info {
  display: flex;
  font-weight: 600;
}
.subtotal-info span {
  color: var(--error);
  font-size: 0.65rem;
  margin-left: 0.25rem;
}

.autoshop-info {
  display: flex;
  /* gap: 16px; */
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.autoshop-info h3 {
  text-decoration: underline;
  color: var(--primary);
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 480px) {
  .container h1 {
    font-size: 1.75rem;
  }
}
