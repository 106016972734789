.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000040;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 550px;
  background: #fff;
  border-radius: 10px;
  overflow: auto;
}
.content.large {
  width: 750px;
}

.content.full {
  width: 100%;
  padding: 0 0.5rem;

  div:nth-of-type(1) > img:nth-of-type(1) {
    width: 45px;
    margin-top: 10px;
  }
}

.header {
  display: flex;
  align-items: flex-start;
  color: var(--primary);
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
  position: relative;
  /* height: 170px; */
}
.header hr {
  position: absolute;
  top: 0;
  left: 0;
  height: 8px;
  width: 100%;
  background-color: var(--primary);
  z-index: 10;
}
.content.large .header {
  padding: 1rem 2.5rem;
}
.header h2 {
  /* align-self: flex-start; */
  text-align: center;
  flex: 1;
  font-family: 'Bebas Neue';
  font-weight: 400;
  font-size: 2.5rem;
  z-index: 2;
  /* margin-top: 1.4rem; */
}

.header h2:focus {
  border: none;
  outline: none;
}

.header .pattern {
  position: absolute;
  top: -10px;
  left: -50px;
  z-index: 1;
}
.header button {
  all: unset;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 10;
}

header .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.body {
  flex: 1;
  width: 80%;
  margin-bottom: 2rem;
  z-index: 1;
}

.header img {
  width: 45px;
  z-index: 2;
}
.header {
  gap: 0.5rem;
}
.footer {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 1rem auto;
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid var(--primary);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.content::-webkit-scrollbar {
  width: 12px;
}
.content::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* firefox */
.content {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) #f1f1f1;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 880px) {
  .overlay {
    bottom: 0;
    min-height: 100vh;
    padding: 1.5rem 0;
  }
  .content,
  .content.large {
    width: 100%;
  }
  .header {
    align-items: flex-start;
  }
  .header h2 {
    font-size: 1.5rem;
  }

  .header img {
    width: 35px;
  }
  .body {
    width: 85%;
  }
}

@media screen and (max-width: 480px) {
  .overlay {
    bottom: 0;
    min-height: 100vh;
    padding: 1.5rem 0;
  }
  .content,
  .content.large {
    width: 100%;
  }
  .header {
    align-items: flex-start;
  }
  .header h2 {
    font-size: 1.5rem;
  }

  .header img {
    width: 35px;
  }
  .body {
    width: 85%;
  }
}
