.modal {
  width: 90vw;
  max-width: 580px;
  padding: 2rem;
  padding-top: 1rem;
  min-height: 250px;

  @media screen and (max-width: 480px) {
    max-width: 480px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  & > span {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }

  .advice {
    color: var(--error);
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  & button {
    height: 32px;

    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;

    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.primary-button {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;

  color: var(--white);

  border-radius: 8px;
  background: var(--primary);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.second-button {
  transition: all 0.2s ease;
  cursor: pointer;

  color: var(--black, #000);
  text-align: center;

  border: 2px solid var(--B_Grey, #263238);

  background: var(--white);
}

.second-button:hover {
  background: var(--Black, #000);
  color: var(--white);
}

.copy-container {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid transparent;
  margin-bottom: 1rem;
  transition: all ease 0.3s;

  &.copied {
    background: var(--tint_3);
    border: 1px solid var(--primary);
  }
  & span {
    height: 48px;
    text-align: center;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    font-size: 1rem;
    font-weight: 500;
  }
}
