.embla {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-left: 3rem;
}
.embla__container {
  padding: 1rem 0;
  display: flex;
  height: 250px;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  cursor: grab;
}

.embla .right,
.embla .left {
  all: unset;
  cursor: pointer;
  position: absolute;
  top: 0;
  z-index: 2;
  height: 100%;
}
.embla .left {
  left: 0;
}
.embla .right {
  right: 0;
}
