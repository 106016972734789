.row {
  margin: 0rem 0;
}
.heading {
  display: flex;
  gap: 1rem;
  margin: 1rem 2rem 2rem auto;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
}

.describe {
  display: flex;
  align-items: center;
  font-family: 'Inter';
  width: 65%;
}

.describe img {
  width: 100px;
}
.describe p {
  font-size: 1.35rem;
  font-weight: 600;
  width: 80%;
  margin-left: 1rem;
}
.actions {
  display: flex;
  align-items: center;
  width: 35%;
  gap: 1rem;
  justify-content: flex-end;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}

@media only screen and (max-width: 480px) {
  .heading {
    flex-direction: column;
    padding: 0;
  }
  .describe {
    width: 100%;
    img {
      width: 70px;
    }
  }
  .actions {
    width: 100%;
    justify-content: left;
  }
  .container div:nth-of-type(2) > div {
    width: 100%;

    div div {
      text-align: center;
    }
  }
}

@media only screen and (max-width: 880px) {
  .heading {
    flex-direction: column;
    padding: 0;
  }
  .describe {
    width: 100%;
    img {
      width: 70px;
    }
  }
  .actions {
    width: 100%;
    justify-content: left;
  }
  .container div:nth-of-type(2) > div {
    width: 100%;

    div div {
      text-align: center;
    }
  }
}
