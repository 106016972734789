.section-separator {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  width: 100%;
}

.section-separator span {
  font-size: 1.25rem;
  color: var(--primary);
  font-weight: 600;
}
