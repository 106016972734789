.container {
  width: 100%;
}

.tooltip {
  width: max-content;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0.5rem;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0%;
  transform: translate(50%, 50%);
  font-size: 0.8rem;
}

@media screen and (max-width: 480px) {
  .tooltip {
    display: none;
    text-align: center;
  }
}
