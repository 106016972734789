.message {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.advice {
  color: var(--error);
  font-size: 14px;
  align-self: center;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;

  & button {
    height: 32px;

    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;

    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.primary-button {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;

  color: var(--white);

  border-radius: 8px;
  background: var(--primary);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.second-button {
  transition: all 0.2s ease;
  cursor: pointer;

  color: var(--black, #000);
  text-align: center;

  border: 2px solid var(--B_Grey, #263238);

  background: var(--white);
}

.second-button:hover {
  background: var(--Black, #000);
  color: var(--white);
}
