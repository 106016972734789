.ghost {
  transition: all 0.2s ease-in-out;

  min-width: 100px;
  padding: 0.6rem 0.85rem 0.6rem 0.75rem;

  color: var(--grey);
  border-radius: 8px;
  text-align: center;
}
.ghost:hover {
  background-color: #abbed11a;
  color: var(--grey);
}

.button {
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 700;
  gap: 0.25rem;
  transition: all 0.2s ease-in-out;
}

.default {
  background-color: var(--secondary);
  color: var(--white);
  padding: 0.6rem 0.85rem 0.6rem 0.75rem;
}
.register {
  font-family: 'Bebas Neue';
  min-width: 100px;
  font-weight: 400;
  font-size: 1.25rem;
  background-color: var(--secondary);
  color: var(--white);
  padding: 0.6rem 0.85rem 0.6rem 0.75rem;
}

.default:hover,
.register:hover {
  background: var(--shade_6);
}

.icon {
  padding: 0.5rem;
  border-radius: 50%;
  aspect-ratio: 1;
  width: 2.5rem;
  height: 2.5rem;
}

.icon:hover {
  opacity: 0.95;
  transform: scale(0.95);
}

.border {
  font-family: 'Bebas Neue';
  color: var(--secondary);
  border: 2px solid var(--secondary);
  background-color: transparent;
  font-weight: 400;
  font-size: 1rem;

  border-radius: 8px;
  padding: 0.25rem 0.5rem;
}

.delete {
  background-color: var(--error);
}

.delete:hover {
  opacity: 0.9;
  background-color: var(--error);
}

.pulse:hover {
  animation: pulse 0.7s ease-in-out infinite;
}

.unstyled {
  all: unset;
  cursor: pointer;
  background: transparent;
}

.navbar {
  border-radius: 8px;
  padding: 1rem 0.85rem;
  align-items: center;
  gap: 1rem;
  font-weight: 800;
  width: 90%;
  justify-content: flex-start;
  white-space: nowrap;
}
.navbar:hover {
  background-color: var(--silver);
  color: var(--secondary);
}
.navbar.selected {
  color: var(--secondary);
  background: var(--silver);
}

.cancel {
  font-size: 1.5rem;
  font-family: 'Bebas Neue';
  padding: 0.25rem 0.75rem;
  border-radius: 8px;
  border: 2px solid #9d1816;
  color: #9d1816;
  background: var(--white);
}

.cancel:hover {
  background: #9d1816;
  color: var(--white);
  transition: all ease-in 0.25s;
}
button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease, box-shadow 0.3s ease;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
