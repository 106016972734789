.switch {
  position: relative;
  display: inline-block;
  width: 3.75rem;
  height: 2rem;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: var(--grey-blue);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 2rem;
  box-shadow: 1px 1px 6px #00000044;
  transform: rotateY(180deg);
}

.slider .thumb {
  position: absolute;
  content: '';
  height: 1.8rem;
  width: 1.8rem;
  right: 4px;
  bottom: 2px;
  background-color: #f5faf5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 1px 1px 6px #00000044;
  display: flex;
  align-items: center;
  justify-content: center;
}
input:checked + .slider {
  background-color: var(--primary);
}
input:checked + .slider .thumb {
  background-color: var(--white);
}

input:checked + .slider .thumb {
  -webkit-transform: translateX(-26px);
  -ms-transform: translateX(-26px);
  transform: translateX(-26px);
}
