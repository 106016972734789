.container {
  display: flex;
  background: #f6f6f6;
  box-shadow: 2px solid #00000044;
  width: 100%;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  margin: 0.5rem auto;
}

.container span {
  font-weight: 600;
}
