.filter-modal {
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  color: var(--primary);

  flex-wrap: wrap;

  & > hr {
    border: none;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgba(0, 122, 55, 0.2) 0%,
      #007a37 50%,
      rgba(0, 122, 55, 0.2) 100%
    );
    width: 100%;
  }

  & > div {
    width: 100%;
  }
}

.container > span {
  font-size: 1rem;
  font-weight: 400;
  color: var(--primary);

  align-items: flex-start;
}

.date-range-wrapper {
  display: flex;
  gap: 2rem;

  align-items: center;
  justify-content: center;
}

.date-range {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
  position: relative;
}

.clear-fields {
  display: flex;
  border: none;

  background-color: transparent;

  font-size: 0.625rem;
  font-weight: 500;

  color: #717171;
}

.modal-title {
  font-size: 1rem;
  font-weight: 400;
  color: var(--primary);

  align-items: flex-start;

  font-family: Bebas Neue;
  font-weight: 400;
  letter-spacing: 0%;
}

.transaction-code-wrapper {
  display: flex;
  gap: 2rem;

  align-items: center;
  justify-content: space-between;

  margin-top: 16px;
  margin-bottom: 16px;

  flex-wrap: wrap;

  & > span {
    font-size: 0.875rem;
    font-weight: 600;

    color: var(--primary);
  }
}

.buttons-wrapper {
  display: flex;
  gap: 1rem;

  & > button {
    width: 100%;
  }
}

@media screen and (max-width: 580px) {
  .filter-modal {
    font-size: 0.875rem;

    & > hr {
      width: 100%;
    }
  }

  .modal-header {
    justify-content: center;
    flex-direction: column;
  }

  .modal-title {
    font-size: 0.875rem;
  }

  .transaction-code-wrapper {
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .date-range-wrapper {
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .date-range {
    /*   */
    flex-direction: column;
    width: 100%;
  }

  .buttons-wrapper {
    gap: 0.5rem;
    flex-direction: column;
  }
}
