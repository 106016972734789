.container {
  width: 15.625rem;
  height: 15.625rem;
  box-sizing: border-box;
  color: #4d4d4d;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  background: #e8f5e9;

  clip-path: polygon(
    11.952% 0%,
    88.051% 0%,
    88.051% 0%,
    90.26% 0.127%,
    92.256% 0.507%,
    94.034% 1.133%,
    95.589% 2.002%,
    96.918% 3.11%,
    98.016% 4.451%,
    98.877% 6.021%,
    99.498% 7.815%,
    99.874% 9.829%,
    100% 12.059%,
    100% 87.941%,
    100% 87.941%,
    99.874% 90.17%,
    99.498% 92.184%,
    98.877% 93.978%,
    98.016% 95.548%,
    96.918% 96.889%,
    95.589% 97.997%,
    94.034% 98.866%,
    92.256% 99.493%,
    90.26% 99.872%,
    88.051% 100%,
    11.952% 100%,
    11.952% 100%,
    9.742% 99.872%,
    7.746% 99.493%,
    5.967% 98.866%,
    4.411% 97.997%,
    3.082% 96.889%,
    1.985% 95.548%,
    1.123% 93.978%,
    0.502% 92.184%,
    0.126% 90.17%,
    0% 87.941%,
    0% 12.059%,
    0% 12.059%,
    0.126% 9.829%,
    0.502% 7.815%,
    1.123% 6.021%,
    1.985% 4.451%,
    3.082% 3.11%,
    4.411% 2.002%,
    5.967% 1.133%,
    7.746% 0.507%,
    9.742% 0.127%,
    11.952% 0%
  );
}

.pattern {
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  width: 30%;
  height: 30%;
  background-color: var(--secondary);
  z-index: 1;
  clip-path: polygon(
    100% 0%,
    100% 0%,
    100% 7.222%,
    100% 14.444%,
    100% 21.667%,
    100% 28.889%,
    100% 36.111%,
    100% 43.333%,
    100% 50.556%,
    100% 57.778%,
    100% 65%,
    100% 72.222%,
    100% 72.222%,
    98.744% 76.298%,
    97.181% 80.075%,
    95.312% 83.554%,
    93.135% 86.733%,
    90.652% 89.614%,
    87.863% 92.196%,
    84.766% 94.479%,
    81.363% 96.464%,
    77.653% 98.15%,
    73.636% 99.537%,
    73.636% 99.537%,
    66.273% 99.704%,
    58.909% 99.833%,
    51.545% 99.926%,
    44.182% 99.982%,
    36.818% 100%,
    29.455% 99.982%,
    22.091% 99.926%,
    14.727% 99.833%,
    7.364% 99.704%,
    0% 99.537%,
    0% 99.537%,
    2.487% 99.298%,
    4.926% 98.926%,
    7.317% 98.423%,
    9.661% 97.788%,
    11.958% 97.02%,
    14.207% 96.121%,
    16.408% 95.09%,
    18.562% 93.926%,
    20.668% 92.631%,
    22.727% 91.204%,
    22.727% 91.204%,
    29.864% 83.12%,
    37.021% 75.052%,
    44.198% 67.001%,
    51.395% 58.967%,
    58.613% 50.949%,
    65.85% 42.948%,
    73.107% 34.964%,
    80.385% 26.996%,
    87.682% 19.045%,
    95% 11.111%,
    95% 11.111%,
    95.671% 10.054%,
    96.304% 8.985%,
    96.898% 7.904%,
    97.455% 6.811%,
    97.974% 5.706%,
    98.455% 4.588%,
    98.898% 3.459%,
    99.303% 2.318%,
    99.671% 1.165%,
    100% 0%
  );
}

.container button {
  all: unset;
  cursor: pointer;
  display: block;
  height: 100%;
  width: 100%;
  padding-bottom: 1rem;
}

.arrow {
  position: absolute;
  right: 4px;
  bottom: 9px;
  width: 1.625rem;
}

.icon {
  width: 3rem;
  height: 3rem;
  position: relative;
}

.content {
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.title {
  font-size: 1.6rem;
  text-align: center;
  font-weight: 700;
  color: var(--black);
  margin-bottom: 0.5rem;
}

.description {
  text-align: start;
  font-size: 1.1rem;
  padding-left: 0.5rem;
  max-width: 90%;
}

@media screen and (max-width: 480px) {
  .container {
    width: 165px;
    height: 165px;
    z-index: 1;
  }

  .content {
    padding-top: 0.25rem;
  }

  .container .description {
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.45rem;
  }

  .arrow {
    width: 1.25rem;
    bottom: 8px;
    right: 2px;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 350px) {
  .container {
    width: 150px;
    height: 150px;
  }

  .title {
    font-size: 1.1rem;
  }
}
