/* reset table css grid-table to flex column */

.scroll {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 2.5rem;
}

/* Estilizando a barra de rolagem no Chrome, Safari e outros navegadores baseados em WebKit */
.scroll::-webkit-scrollbar {
  width: 2px;
  height: auto;
}

.scroll::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #e1e5e9;
  border-radius: 8px;
}
.scroll::-webkit-scrollbar-thumb:hover {
  background: #c0c4c8;
}

.table-container {
  table-layout: fixed;
}

tr th:first-child {
  border-top-left-radius: 8px;
}
tr th:last-child {
  border-top-right-radius: 8px;
}
