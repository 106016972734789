.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.subtotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e8f5e9;
  padding: 0.5rem 2rem;
  border-radius: 8px;
  font-family: 'Inter';
  font-weight: 600;
}
.subtotal span:last-child {
  font-weight: 800;
  color: var(--primary);
}
.observation {
  padding: 0 2rem;
  font-family: 'Inter';
}
.observation h1 {
  font-size: 1rem;
  font-weight: 600;
}

.observation h1 span {
  font-weight: 400;
  color: var(--grey);
}

.actions {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.actions button {
  font-size: 24px;
}

.tax-value {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.tax-value span {
  width: 250px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.tax-value input {
  width: 100px;
}
