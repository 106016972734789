.button {
  all: unset;
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  color: var(--white);
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  border-radius: 50%;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
}

.button.visible {
  display: flex;
}
