.main {
  margin: 6rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.presentation {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 8rem;
  flex-wrap: wrap;
}

.mainCard {
  background-image: url('../../../../../assets/img/LandingPage/mainCard_LandingPage.svg');
  background-repeat: no-repeat;
  color: white;
  text-align: left;
  padding: 1.5rem 5.2rem 3rem 2.5rem;
  border-radius: 30px;
  width: 30rem;
}

.mainCard > header {
  display: grid;
  grid-template-columns: auto 5rem;
  align-items: center;
  gap: 0.5rem;
}

.mainCard > header > h1 {
  font-weight: 400;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 3rem;
}

.mainCard > header > img {
  height: 4rem;
}

.mainFigure {
  width: 20rem;
  height: fit-content;
}

.information {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.information > header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 28rem;
  text-align: center;
  margin: 4rem 0;
  align-self: center;
}

.information > header > strong {
  color: var(--d_grey);
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.information p {
  color: var(--grey);
}

.tipCards {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.tipCards > article {
  position: relative;
  z-index: 10;
  background-color: white;
}

.backgroundPattern1 {
  z-index: 0;
  position: absolute;
  top: 23rem;
  right: 0;
  height: 22rem;
}

.backgroundPattern2 {
  z-index: 0;
  position: absolute;
  top: 40rem;
  left: 0;
  height: 22rem;
}

.invitation {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  padding-bottom: 0.5rem;
}

.invitation > strong {
  font-size: 1.5rem;
  line-height: 2.5rem;
  width: 30rem;
  color: var(--b_grey);
}

.invitation > button {
  margin-top: 1.5rem;
  align-self: center;
}

.backgroundPatternSmall1 {
  display: none;
}

.backgroundPatternSmall2 {
  display: none;
}

@media screen and (max-width: 900px) {
  .main {
    margin: 8rem 0 0;
    padding: 0rem 2.5rem;
    text-align: center;
  }

  .mainFigure {
    height: 16rem;
    margin-bottom: 3rem;
  }

  .presentation {
    display: flex;
    flex-direction: column;
    margin: 0;
    gap: 0;
    align-items: center;
  }

  .information {
    margin-top: 2rem;
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .information > header {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 18.5rem;
    margin-top: 0;
  }

  .information > header strong {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.4rem;
  }

  .information > header p {
    font-size: 1rem;
    align-self: center;
    width: 23rem;
  }

  .tipCards {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .backgroundPattern1 {
    display: none;
  }

  .backgroundPattern2 {
    display: none;
  }

  .backgroundPatternSmall1 {
    display: flex;
    z-index: 0;
    position: absolute;
    top: 35rem;
    left: 0em;
    height: 20rem;
  }

  .backgroundPatternSmall2 {
    display: flex;
    z-index: 0;
    position: absolute;
    top: 75rem;
    right: 0;
    height: 20rem;
  }

  .invitation {
    margin: 2rem 0rem;
  }

  .invitation > strong {
    font-size: 1.5rem;
    display: block;
    width: 22rem;
    align-self: center;
    line-height: 2rem;
  }

  .invitation > button {
    margin: 1rem 0rem;
  }
}

@media screen and (max-width: 480px) {
  .mainCard {
    width: 23rem;
    padding: 1.5rem 2.5rem 2rem 2rem;
    background-size: contain;
  }

  .mainCard > header {
    gap: 1rem;
    padding-right: 1.3rem;
    padding-bottom: 0.5rem;
  }

  .mainCard > header > h1 {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 2.2rem;
    margin-bottom: 0;
    padding: 0;
  }

  .mainCard > header > img {
    width: 5.3rem;
  }

  .mainCard > p {
    font-size: 1rem;
  }

  .information p {
    max-width: 300px;
  }
}
