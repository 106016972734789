.cell {
  padding: 1.3rem;
  text-align: center;

  font-size: 1rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
}
.align-start {
  text-align: start;
}
