.container {
  border: 2px solid var(--primary);
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 85%;
  padding: 1rem 1.5rem;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.checklist-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.checklist-container h3 {
  font-weight: 700;
  font-size: 1rem;
  color: var(--b_grey);
  display: flex;
  align-items: center;
}

.images-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.checklist-container li {
  list-style: none;
}

.checklist-container li img {
  width: 250px;
  max-height: 300px;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .container {
    width: 95%;
  }
  .row {
    flex-wrap: wrap;
  }
}
