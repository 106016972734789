.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.empty-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.empty-message {
  font-family: 'montserrat';
  font-weight: 700;
  font-size: 1.5rem;
}

.input-control {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 3px solid #ccc;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;

  & input {
    all: unset;
    flex: 1;
  }
}
