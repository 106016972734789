.search-bar-container {
  display: flex;
  align-items: center;
  gap: 1rem;

  position: relative;
}
.input-control {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 26.25rem;
  border: 1px solid #a5a5a5;
  padding: 0.25rem;
  border-radius: 8px;
  /* margin-left: 2.5rem; */
}
.input-control input {
  all: unset;
  height: 100%;
  font-size: 1rem;
  padding-left: 0.5rem;
  width: 100%;
}
.input-control input::placeholder {
  color: #a5a5a5;
}
.search-bar-container .filter-select {
  all: unset;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #a5a5a5;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 480px) {
  .search-bar-container {
    width: 100%;
  }

  .input-control {
    margin-left: 0;
    width: auto;
    height: 3.5rem;
  }
  /* .search-bar-container .filter-select {
    display: flex;
  } */
}

@media screen and (max-width: 880px) {
  .input-control {
    margin-left: 0;
    /* width: auto; */
    height: 3.5rem;
  }
}
