.card {
  display: flex;
  gap: 5rem;
  justify-content: center;
  border-radius: 40px;
  padding: 1.5rem 4rem;
  align-self: center;
}

.cardNormal {
  flex-direction: row;
}

.cardReverse {
  flex-direction: row-reverse;
}

.titleSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.8rem;
  margin-left: 3rem;
}

.titleSection > h2 {
  font-family: Inter;
  font-weight: 600;
  color: #4d4d4d;
  padding: 0.5rem 0;
  font-size: 1.8rem;
  z-index: 100;
  max-width: 500px;
}

.titleSection > p {
  font-family: Inter;
  color: #717171;
  font-size: 1rem;
  max-width: 465px;
  z-index: 100;
}

.image {
  height: 20rem;
  position: relative;
  z-index: 10;
  max-width: 33rem;
}

.hightlightBackground {
  background-color: #f5f7fa;
}

@media screen and (max-width: 800px) {
  .card {
    gap: 2rem;
    min-width: 0;
    padding: 1.5rem 2rem;
  }
  .titleSection {
    min-width: 0;
    max-width: 85dvw;
  }
  .cardNormal {
    flex-direction: column;
  }
  .cardReverse {
    flex-direction: column;
  }
  .image {
    height: 16rem;
    align-self: center;
  }
}

@media screen and (max-width: 480px) {
  .titleSection {
    gap: 0.2rem;
    margin: 0;
  }
  .titleSection > h2 {
    font-size: 1.6rem;
  }
  .titleSection > p {
    font-size: 1.25rem;
  }
  .card {
    padding: 1.5rem 1.5rem;
  }
  .image {
    height: 13rem;
    min-width: none;
  }
}
