.container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-top: 1rem;
  width: 45%;
}

@media screen and (max-width: 480px) {
  .content {
    width: 80%;
  }
}
