.container {
  display: flex;
  min-width: 15rem;
  padding: 1rem 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.875rem;

  border-radius: 0.5rem;
  background: #e1e5e9;
  position: relative;

  &.auto {
    &.green {
      background-color: var(--tint_5);
    }
    &.red {
      background-color: #fdeded;
    }
  }
}

.date {
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  position: absolute;
  right: 32px;
  top: 0;

  border-radius: 0 0 12px 12px;
  background: var(--primary);
}

.title {
  color: var(--b_grey);
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 700;
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 1rem;

  svg {
    height: 1.25rem;
    width: auto;
  }

  &.red,
  &.paymentDay {
    svg {
      color: #c71d1a;
    }
  }

  &.green,
  &.receiveDay {
    svg {
      color: var(--primary);
    }
  }
}

.lightRed {
  background-color: #fdeded;
}

.red {
  background-color: #fdeded;
}

.gray {
  background-color: #e1e5e9;
}

.lightGreen {
  background-color: #e8f5e9;
}

.green {
  background-color: #e8f5e9;
}

.value {
  display: flex;
  align-items: baseline;

  &.red > div:first-child {
    color: var(--error-text, #c71d1a);
  }

  &.lightRed > div:first-child {
    color: var(--error);
  }

  &.lightGreen > div:first-child {
    color: var(--secondary);
  }
  &.green > div:first-child {
    color: var(--primary);
  }
}

.integer {
  color: var(--black);
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: 800;
}

.receiveDay .integer.emphasis {
  color: var(--primary);
}
.paymentDay .integer.emphasis {
  color: var(--error-text);
}

.decimal {
  color: var(--grey);
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 800;
}
