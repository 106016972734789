.container {
  max-width: 500px;
  padding: 1rem;
}

.controls {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  background: #ffffff80;
  z-index: 10;
}

.image {
  width: 350px;
  height: auto;
}

@media screen and (max-width: 480px) {
  .container {
    max-width: 100vw;
  }
  .image {
    max-width: 350px;
  }
}
