.container {
  background-color: #f5f7fa;
  width: 100%;
  position: relative;
}

.input-control {
  width: 100%;
  border: 1px solid rgba(84, 76, 76, 0.25);
  border-radius: 8px;
  height: 3rem;
  cursor: text;
  padding: 0.25rem 1rem;
  display: flex;
  align-items: center;
}
.input-control .open-list {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-control .open-list .icon {
  width: 1rem;
  height: 1rem;
}
.input-control input {
  all: unset;
  width: 100%;
  height: 100%;
  caret-color: var(--primary);
}

.options {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f5f7fa;
  border: 1px solid rgba(84, 76, 76, 0.25);
  width: 100%;
  gap: 1rem;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  padding: 0.75rem 0.85rem;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.options.open {
  display: flex;
}

.option {
  list-style: none;
  background: var(--white);
  padding: 0.65rem 1rem;
  border-radius: 8px;
  color: var(--d_grey);
  font-weight: 500;
  font-size: 1rem;
}
.option button {
  all: unset;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.option img {
  width: 1.2rem;
}
.info {
  display: flex;
  flex-direction: column;
}

.register {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--tint_5);
  border-radius: 8px;
  padding: 0.65rem 1rem;
  gap: 1rem;
  color: var(--d_grey);
  font-weight: 500;
}

.register img {
  width: 2rem;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
  }
}
