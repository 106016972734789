.menu-navigation {
  flex: 0 0 1;

  overflow-y: scroll;
  scrollbar-width: none;

  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  align-items: center;
  gap: 8px;

  background-color: var(--white);
}

@media screen and (max-width: 1440px) {
  /* menu navigation absoluto para hover effect */
  .menu-navigation {
    position: absolute;
    height: 100%;
    z-index: 2;
  }

  .menu-navigation:hover {
    width: 17.75rem;
  }

  /* efeitos aplicados diretamente em todos os botões assim que o hover no menu é aplicado */

  .menu-navigation:hover > button {
    width: 15.75rem;

    transition: width 0.5s ease-out;
  }

  .menu-navigation:hover span {
    opacity: 1;
    visibility: visible;
    transform: none;
    position: initial;
    transition: opacity 0.7s ease-out, visibility 0.7s ease-out,
      width 0.5s ease-out, position 0.7s;
  }
}

@media screen and (max-width: 740px) {
  .menu-navigation {
    flex-direction: row;
    justify-content: space-evenly;
    height: auto;
    width: 100%;
    padding: 0.25rem 1rem;

    position: initial;

    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  }

  .menu-navigation:hover {
    width: 100%;
  }
  .menu-navigation:hover > button {
    width: fit-content;
  }

  .menu-navigation:hover span {
    display: none;
  }
}
