.container {
  position: relative;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
}

.section {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 3rem;
}

.section h1 {
  border-bottom: 2px solid var(--primary);
  display: inline;
  color: var(--primary);
  text-shadow: 0px 14px 20px var(--primary);
}

.save-btn {
  all: unset;
  cursor: pointer;
  display: flex;
  max-width: 300px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: var(--2s-scale-6, 6px);
  border-radius: 4px;
  background: var(--secondary);
  color: var(--white);
  position: fixed;
  bottom: 1rem;
  /* right: 0.5rem; */
  right: 6.5vw;
}

.save-btn:hover {
  transform: scale(0.95);
  box-shadow: 1px 1px 10px 0px var(--primary);
}

/* input */

.input-controller {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--2s-scale-4, 4px);
  width: 100%;
}

.input-controller.error i {
  color: var(--error, #e53835);
  font-family: Montserrat;
  font-size: var(--Text-2xs, 12px);
  font-weight: 500;
}

.input-controller span {
  color: var(--black);
  font-family: Montserrat;
  font-size: var(--2s-scale-16, 16px);
  font-weight: 500;
}

.input-controller input,
.input-controller select {
  display: flex;
  min-height: 48px;
  padding: var(--2s-scale-8, 8px) var(--Input-Field-X-padding, 24px);
  justify-content: flex-end;
  align-items: center;
  gap: var(--2s-scale-8, 8px);
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Select-filtroCreateCotation-empty, #89939e);
  background: #eef1f6;
}

.input-controller.error input {
  border: 2px solid var(--Error, #e53835);
  background: #fff5f5;
}

.input-controller select option {
  font-weight: 500;
}

.show-password-controller {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-top: 0.5rem;
}

@media screen and (max-width: 480px) {
  .row {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 880px) {
  .row {
    flex-wrap: wrap;
  }
}
