.container h1,
.container p {
  font-family: 'Inter';
}
.container {
  display: flex;
  align-items: center;
  background: #fafbfc;
  padding: 1rem 1.5rem 1rem 3.5rem;
  border-radius: 20px;
  margin-bottom: 1rem;
  width: 90%;
  gap: 4rem;
  z-index: 1;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: var(--grey);
}
.content h1 {
  color: var(--d_grey);
  font-weight: 600;
  font-size: 2.2rem;
}
.content p {
  font-size: 1.1rem;
}

.container img {
  max-width: 30rem;
}

@media screen and (max-width: 480px) {
  .container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem;
  }

  .container img {
    max-width: 80dvw;
    aspect-ratio: 16 / 9;
  }
}
