.container {
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.container h1 {
  color: var(--black);
  text-align: center;
  font-family: Montserrat;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.autoshops-list {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.autoshops-list li {
  list-style: none;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0.5rem 0.5rem;
  border-radius: 8px;
  background: #e1e5e9;
  text-align: center;
}
