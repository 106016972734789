.container {
  width: 55%;
  font-family: 'Inter';
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  max-height: 500px;
  padding-bottom: 1rem;
  overflow-y: auto;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
}

.answers-list {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}

.table-heading {
  font-size: 1.25rem;
}
.requested-quantity {
  display: flex;
  gap: 0.5rem;
}
.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.answers-info {
  background: var(--info);
  border-radius: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1rem;
}

.wrapTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wrapTitle > div {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  background-color: #eaeff4;
  padding: 0.5rem;
  border-radius: 4px;
  width: 110px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    span {
      text-align: right;
      width: 80%;
      font-size: 0.8rem;
    }
  }
}
.empty-proposals {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.empty-proposals img {
  width: 50%;
  object-fit: contain;
}
.empty-proposals b {
  color: var(--grey);
  font-size: 1.35rem;
  font-family: 'Inter';
}

/* workshops list */

.workshops-list {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.workshops-list li {
  list-style: none;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0.5rem 0.5rem;
  border-radius: 8px;
  background: #e1e5e9;
  text-align: center;
}

.quotation-resume {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.quotation-resume h3 {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.resume-list {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.resume-list li {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  border: 2px solid var(--primary);
  font-size: 1.5rem;
  padding: 0.5rem 0.25rem;
  border-radius: 4px;
  min-width: 240px;
  cursor: pointer;
  margin-bottom: 1rem;

  &:hover {
    background: var(--primary);
    color: var(--white);
    transition: 0.25s ease-in;
  }

  .counter {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .answers-list {
    margin-bottom: 1.5rem;
  }

  .quotation-resume .resume-list {
    margin-bottom: 1rem;
    overflow-y: auto;
  }
}
