.account-control {
  display: flex;
  max-width: 100%;
}

.account-control-profile {
  gap: 0.5rem;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    padding: 0.25rem;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

    border-radius: 0.25rem;

    border: none;
    background-color: white;
    font-size: 1rem;
  }
}

.account-control-profile-name {
  gap: 0.25rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.signout {
  all: unset;
  cursor: pointer;

  display: flex;
}
