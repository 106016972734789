.vehicle-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0b5226;
  color: #f5faf5;

  font-size: 1.35rem;
  padding: 1.5rem;
  height: 250px;
  width: 60%;
  border-radius: 8px;
  position: relative;
}
.vehicle-form h2 {
  font-size: 1.2rem;
  padding: 0;
}
.vehicle-form img {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.vehicle-form form {
  width: 80%;
}

.vehicle-form .input-control span,
.vehicle-form .input-control input {
  color: #f5faf5;
}

.vehicle-form .input-control input {
  padding-left: 0.5rem;
  background-color: #88b787;
  border-radius: 8px;
  height: 2.5rem;
}

.input-control {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-block: 0.5rem;
}

.input-control input {
  padding-left: 0.5rem;
  all: unset;
  height: 2.5rem;
}
.input-control input:disabled {
  cursor: not-allowed;
}
.input-control span {
  font-size: 1rem;
  font-weight: 500;
  color: #4b4b4b;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .vehicle-form {
    width: 95%;
  }
}

@media screen and (max-width: 980px) {
  .vehicle-form {
    width: 95%;
  }
}
