.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  margin-top: 2rem;
}

.container h1 {
  font-weight: 500;
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--primary);
}

.transaction-code-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50%;
  max-height: 300px;
  overflow: auto;
}

.new-transaction-code-wrapper {
  display: flex;
  align-items: flex-end;
  width: 50%;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;


}


@media screen and (max-width: 740px) {

  .new-transaction-code-wrapper,
  .transaction-code-list {
    width: 80%;
  }

  .transaction-code-list {
    margin-bottom: 2rem;
  }
}