.row {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  gap: 1rem;
}

.repairs-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 60dvh;
  align-items: center;
  gap: 1rem;
}
@media screen and (max-width: 768px) {
  .row {
    flex-wrap: wrap;
  }
  .column {
    width: 95%;
  }
}
