.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  width: 100%;

  gap: 1.125rem;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-family: Bebas Neue;
  font-weight: 400;
  font-size: 2.375rem;
  line-height: 2.625rem;
  letter-spacing: 0%;
}

.action-buttons {
  display: flex;

  flex-direction: row;
  gap: 1rem;

  justify-content: space-between;

  color: var(--white);
  font-size: 1rem;
  font-weight: 600;

  line-height: 19.5px;
}

.receive-button {
  all: unset;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;

  box-sizing: border-box;

  padding: 0.75rem 2.063rem 0.75rem 1rem;

  background-color: #007a37;
  border-radius: 0.5rem;

  cursor: pointer;

  transition: background-color 0.2s ease-in-out;
}

.payment-button {
  all: unset;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;

  box-sizing: border-box;

  padding: 0.75rem 3.375rem 0.75rem 1rem;

  background-color: #e53835;
  border-radius: 0.5rem;

  cursor: pointer;

  transition: background-color 0.2s ease-in-out;
}

.modal-title {
  text-align: start;
  align-self: flex-start;
}

.modal-title.income {
  color: var(--primary);
}
.modal-title.expense {
  color: var(--error-text, #c71d1a);
}

.transactions-page-header {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;

  flex: 1;

  border-radius: 8px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;

  position: relative;

  overflow: auto;
}

.checkboxes {
  display: flex;
  flex-direction: column;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
  padding: 0.25rem;
  width: 150px;
  height: 50px;
}
.checkbox-control {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.release-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 2rem 1.6rem;
}

.release-list--header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.filters {
  display: flex;
  align-items: center;

  justify-content: space-between;
}

.filters-container {
  display: flex;

  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  gap: 1rem;
}

.button-wrapper {
  display: flex;
  position: relative;
}

.filter-button {
  all: unset;
  display: flex;

  box-sizing: border-box;
  border: none;

  background-color: #f5f7fa;
  /* width: 81px;
  height: 33px; */
  padding: 0.5rem;
  border-radius: 9999px;

  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 700;

  gap: 0.25rem;
  cursor: pointer;
}

.filter-button.active {
  background-color: #007a37;
  color: #ffffff;
}

.filters-counter {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fd4949;

  width: 14px;
  height: 14px;

  border-radius: 9999px;
  padding-top: 2px;
  padding-right: 4px;
  padding-bottom: 2px;
  padding-left: 4px;

  color: #ffffff;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.762rem;
  letter-spacing: 0%;

  position: absolute;
  top: -5px;
  right: 10px;
}

.filter-applied {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  background-color: #f5f7fa;

  padding: 0.5rem;
  border-radius: 9999px;

  font-size: 14px;
  font-weight: 700;
}

.filter-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.filter-wrapper h1 {
  font-weight: 700;
}
.filter-wrapper .filters {
  display: flex;
  gap: 1rem;
}

.cards {
  flex: 1;
  display: flex;
  gap: 1rem;
  > div {
    flex: 1;
  }
  overflow-x: scroll;
  padding-bottom: 0.5rem;
}

.cards::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}
.cards::-webkit-scrollbar-thumb {
  background-color: var(--primary);
}

.list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  > div {
    display: flex;
    gap: 1rem;
    flex: 1;
  }
}

.reset {
  height: 24px;
  width: auto;
  transform: scaleX(-1);
  rotate: -40deg;
  transition: 600ms;
  cursor: pointer;
  &:hover {
    rotate: 320deg;
  }
}

.loading-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.filters {
  display: flex;
}
.search-filter {
  display: flex;
  width: 100%;
}

.date-filter {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media only screen and (max-width: 1600px) {
  .action-buttons {
    font-size: 1rem;
    flex-wrap: wrap;
  }
}

@media (width <= 1185px) {
  .transactions-page-header {
    flex-direction: column;
  }
  .filter-wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media (width <= 904px) {
  .cards {
    max-width: 100%;
    overflow-x: scroll;
    padding-bottom: 4px;

    > div:last-child {
      order: -1;
    }

    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--primary); /* Cor do polegar */
      border-radius: 10px; /* Bordas arredondadas */
    }
  }

  .content {
    min-height: 100%;
  }
}

@media (width <= 768px) {
  .header-wrapper {
    flex-wrap: wrap;
  }

  .receive-button {
    width: 100%;
  }

  .payment-button {
    width: 100%;
  }

  .action-buttons {
    width: 100%;
  }
}

@media (width <=490px) {
  .filters {
    flex-wrap: wrap;
  }
  .filter-wrapper {
    flex-direction: column;
    > div {
      width: 100%;
      display: flex;
      justify-content: center;
      > select {
        flex: 1;
      }
    }
  }

  .payment-button {
    padding: 1.3rem 0.6rem;
  }

  .receive-button {
    padding: 1.3rem 0.6rem;
  }

  .cards {
    flex-direction: column;
    gap: 0.5rem;
    overflow: auto;
    > div:last-child {
      order: 1;
    }

    > div {
      align-items: center;
    }
  }
}
