:root {
  --swiper-pagination-color: #0b5226;
}
.container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  justify-content: center;
  padding-top: 100px;
  gap: 1rem;
  position: relative;
}

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: -2rem;
  padding-left: 4.5rem;
}

.title > h1 {
  font-size: 5rem;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 300;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: auto;
  margin-bottom: 6rem;
}

.lastText {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.titleSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.lastText h2 {
  text-align: center;
  font-family: Inter;
  font-weight: 600;
  font-size: 1.8rem;
  color: #4d4d4d;
}

.lastText p {
  text-align: center;
  align-self: center;
  font-family: Inter;
  max-width: 465px;
  color: #717171;
}

.imageGroup {
  width: 80%;
  aspect-ratio: auto;
  display: none;
  justify-content: center;
  gap: 1.5rem;
  position: relative;
  z-index: 10;
}

.imageGroup div {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
}

.carousel {
  width: 17.5rem;
  height: 17.5rem;
  position: relative;
  z-index: 10;
}

.carouselImage {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 17.5rem;
  border-radius: 10px;
}

.backgroundPattern1 {
  z-index: 0;
  position: absolute;
  top: 68rem;
  right: 0;
  height: 18rem;
}

.backgroundPattern2 {
  z-index: 0;
  position: absolute;
  top: 17rem;
  left: 0rem;
  height: 18rem;
}

.botoesAplicativo {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  z-index: 100;
  gap: 0.2rem;
}

.botaoAndroid {
  border: 0;
  width: 11rem;
  height: 3.5rem;
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url('../../../../../assets/img/LandingPage/AppAndroid_button.svg');
  background-size: cover;
  border-radius: 13px;
}

.botaoIOS {
  border: 0;
  width: 11rem;
  height: 3.5rem;
  background-color: transparent;
  background-image: url('../../../../../assets/img/LandingPage/AppIOS_button.svg');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 13px;
  margin-right: auto;
}

@media (min-width: 768px) {
  .imageGroup {
    display: flex;
  }
  .image {
    max-width: 50%;
  }
  .carousel {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .title {
    padding-left: 4%;
  }
  .title > h1 {
    font-size: 3.8rem;
  }
  .backgroundPattern2 {
    width: 12.5rem;
  }
  .backgroundPattern1 {
    width: 12.5rem;
  }
  .section {
    gap: 1rem;
  }
  .lastText {
    padding: 0 2rem;
  }
  .lastText h2 {
    font-size: 1.6rem;
  }
  .lastText p {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 480px) and (max-width: 800px) {
  .title {
    padding-left: 1.8rem;
  }
  .section {
    gap: 1rem;
  }
}

@media screen and (min-width: 800px) and (max-width: 1200px) {
  .title {
    padding-left: 3.5rem;
  }
  .section {
    gap: 1rem;
  }
}

/* Alinhamento do título*/
@media screen and (min-width: 1200px) {
  .section {
    padding: 0 5rem;
  }
}
