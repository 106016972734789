.container {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;

  display: flex;
  flex: 1;
  gap: 1rem;
  /* justify-content: space-evenly; */
  /* align-items: center; */
}

.container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.container::-webkit-scrollbar-thumb {
  background: #888;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.addImageBtn {
  all: unset;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.addImageBtn:hover {
  opacity: 0.8;
}

.imagesList {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.imagesList li {
  list-style: none;
  flex-shrink: 0;
  cursor: pointer;
}

.imagesList li:hover {
  background: var(--tint_5);
}

.imagesList li img {
  width: 100%;
  max-width: 150px;
  max-height: 150px;
  height: auto;
  object-fit: contain;
}
