.header {
  display: grid;
  width: 100%;
  grid-template-columns: 4rem 1fr;
  place-items: center;
  padding: 1rem 1.5rem;
}
.header h1 {
  font-weight: normal;
  font-size: 2.2rem;
  text-align: center;
  color: #1e1e1e;
}

@media screen and (max-width: 480px) {
  .header h1 {
    font-size: 1.5rem;
  }
}
