.row {
  margin: 0rem 0;
}
.heading {
  display: flex;
  gap: 1rem;
  margin: 1rem 2rem 2rem auto;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  justify-content: space-between;
}

.describe {
  display: flex;
  align-items: center;
  font-family: 'Inter';
  width: 65%;
}

.describe img {
  width: 100px;
}
.describe p {
  font-size: 1.35rem;
  font-weight: 600;
  width: 80%;
  margin-left: 1rem;
}

.actions {
  display: flex;
  align-items: center;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-height: 60vh; */
}

@media only screen and (max-width: 480px) {
  .heading {
    flex-direction: column;
    padding: 0;
  }
  .describe {
    width: 100%;
    img {
      width: 70px;
    }
  }
  .actions {
    width: 100%;
    div div input {
      width: 100%;
      border-radius: 8px;
    }
    button:nth-of-type(1) {
      display: none;
    }
    button:nth-of-type(2) {
      padding: 0.6rem 0.85rem 0.6rem 0.75rem;
    }
  }

  .container > div:nth-of-type(1) {
    width: 100%;
  }

  .container > div:nth-of-type(2) > div {
    width: 100%;
  }
}

@media only screen and (max-width: 880px) {
  .heading {
    flex-direction: column;
    padding: 0;
  }
  .describe {
    width: 100%;
    img {
      width: 70px;
    }
  }
  .actions {
    width: 100%;
    div div input {
      width: 100%;
      border-radius: 8px;
    }
    button:nth-of-type(1) {
      display: none;
    }
    button:nth-of-type(2) {
      padding: 0.6rem 0.85rem 0.6rem 0.75rem;
    }
  }

  .container > div:nth-of-type(1) {
    width: 100%;
  }

  .container > div:nth-of-type(2) > div {
    width: 100%;
  }
}
