.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  align-items: center;
  padding: 2rem 1.6rem;
  align-self: normal;
}

.release-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 2rem 1.6rem;
}

.release-list--header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  justify-content: center;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.filter-wrapper {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  width: 50%;

  @media only screen and (max-width: 740px) {
    width: 80%;
  }
}

.filter-wrapper h1 {
  font-weight: 700;
}

.filter-label {
  color: #b4b3b8;
}

.filters {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.filter-wrapper--select {
  color: #7c7c7c;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 0.5rem;
  width: 120px;
}

.release-list--content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  overflow-y: auto;
  height: 70vh;
}

.release-list--content::-webkit-scrollbar {
  width: 6px;
}

.release-list--content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.green-button {
  all: unset;
  background: #6bb56a;
  padding: 0.5rem 0.75rem;
  border-radius: 8px;
  color: #fff;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
}

.report-list--header {
  width: 100%;
  list-style: none;
  padding: 0 0.5rem;

  display: grid;
  grid-template-columns: 200px repeat(4, 180px) auto;
  align-items: center;
  text-align: center;
}

.left {
  text-align: left;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.marginTop {
  margin-top: 0.5rem;
}

.date {
  color: #7c7c7c;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 0.5rem;
}

.container-input {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container-input input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  background-color: #eee;
}

.checked {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.container-input:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container-input input ~ .checkmark:after {
  display: block;
}

.container-input .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input-control {
  display: flex;
  flex-direction: column;
}
.input-control label {
  font-weight: 600;
}

.input-icon-control {
  width: 100%;
  position: relative;
}

.input-icon-control input {
  width: 100%;
}

.input-icon-control .icon {
  all: unset;
  position: absolute;
  top: 50%;
  right: 10px;
  cursor: pointer;
  transform: translateY(-50%);
}

@media screen and (max-width: 480px) {
  .filter-wrapper {
    width: 90%;
  }
}
