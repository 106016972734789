.container {
  display: flex;
  width: 575px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;

  border-radius: 8px;
  background: var(--silver, #f5f7fa);
  margin-right: 16px;
  animation: opening-animation 0.24s ease forwards;
  transition: all 0.25s;

  position: relative;
}

.container > div {
  animation: opacity-animation 1s 0.25s ease forwards;
  transition: all 0.25s;
  opacity: 0;
}

.closed {
  font-family: 'Bebas Neue';
  font-size: 1.5rem;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: var(--silver);
  cursor: pointer;
  width: 575px;
  height: 100px;
  text-align: center;
}

.icon {
  position: absolute;
  top: 8px;
  right: 8px;

  cursor: pointer;

  &:hover {
    scale: 1.03;
  }
}

.quotation-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.quotation-info--label {
  color: var(--black, #000);
  font-family: Montserrat;
  font-size: 1.5rem;
  font-weight: 600;
}

.quotation-info--value {
  color: #5e5f60;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 600;
  padding-left: 8px;
}

.text-info {
  display: grid;
  grid-template-columns: 1fr 4fr;
  padding-left: 8px;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  margin-block: 1rem;
}

.text-info--label {
  color: var(--primary, #007a37);
  font-family: 'Bebas Neue';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-info--value {
  color: var(--black);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.section-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.section-info hr {
  border-color: var(--primary);
}

.section-info header {
  padding-left: 8px;
}

.section-info--label {
  color: var(--primary, #007a37);
  font-family: 'Bebas Neue';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.section-info--value {
  color: var(--black, #000);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.comment {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
}

.comment--label {
  color: var(--primary, #007a37);
  font-family: 'Bebas Neue';
  font-size: 16px;
  font-weight: 400;

  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 10px;
  justify-content: center;
  position: absolute;
  left: 16px;
  top: -10px;

  background: var(--white, #fff);
  border-radius: 4px;
}

.comment--value {
  display: flex;
  min-height: 80px;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: flex-start;

  border-radius: 4px;
  background: var(--white, #fff);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
}

.banner-list {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 575px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 8px;
}

.banner-list::-webkit-scrollbar {
  width: 4px; /* Deixa o scrollbar mais fino */
}

.banner-list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.banner-list::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.banner-list::-webkit-scrollbar-track {
  background: transparent;
}

@media screen and (max-width: 480px) {
  .text-info--label,
  .text-info--value,
  .section-info--label,
  .section-info--value {
    font-size: 1rem;
  }
}

@keyframes opening-animation {
  0% {
    max-height: 0;
    overflow: hidden;
  }
  100% {
    max-height: 1000px;
  }
}

@keyframes opacity-animation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
