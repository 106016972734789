.container {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container section {
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  height: 88vh;
  overflow: hidden;
  align-items: center;
}
.actions {
  display: flex;
  gap: 1.5rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2.5rem;
}
.info {
  display: flex;
  gap: 1rem;
  height: 100%;
}
.info .button {
  display: flex;
  font-size: 1.25rem;
}

.order-service-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: var(--white);
}
.order-service-list-header {
  position: sticky;
  top: 0;
  z-index: 1;
  /* background-color: #f0f0f0; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.order-service-list .header {
  width: 95%;

  display: grid;
  align-items: center;
  background-color: #e9e9e9;
  color: #1e1e1e;
  font-weight: bold;

  grid-template-columns: 0.5fr repeat(5, 1fr);
  justify-items: center;
  padding: 0.75rem;
  border-radius: 8px;
}

.search {
  width: 50%;

  button {
    all: unset;
    cursor: pointer;
  }
}

.order-service-list-content {
  width: 95%;

  margin-top: 1rem;

  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}
.order-service-list-content-wrapper {
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}
.empty {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  color: #1e1e1e;
  font-size: 2rem;
}
.mobile {
  display: none;
}

/* tabela de OS */
.table-container {
  height: 400px;
  overflow-y: scroll;
  position: relative;
}

.table {
  width: 100%;
  position: relative;
}
.table-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-row {
  cursor: pointer;

  &:hover,
  &:hover ~ div > * {
    background: var(--tint_5);
  }

  & .first-column {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    height: 100%;
  }
}

.table-actions {
  height: 70px;
  position: relative;
}

.action-buttons {
  position: absolute;
  max-width: 87vw;
  overflow: auto;
}

.action-scroll {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-inline: 0.5rem;

  padding-bottom: 0.25rem;
  & button {
    min-width: 125px;
    font-size: 1rem;
  }
}

.draft-tag {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.125rem;
  color: var(--primary);
  border: 2px solid var(--primary);
  font-weight: 700;
  border-radius: 8px;
  font-size: 0.75rem;
  background-color: var(--white);
  width: auto;
}

@media screen and (max-width: 480px) {
  .container section {
    height: 85vh;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
    width: 90%;
  }
  .order-service-list .header {
    grid-template-columns: repeat(3, 1fr);
  }
  .order-service-list-content {
    overflow-y: scroll;
  }
  .actions {
    padding: 1rem 1rem;
    font-size: 12px;
    text-align: center;
  }

  .search {
    width: 70%;
  }

  .order-service-list-content-wrapper {
    max-height: 60vh;
  }
  .info {
    gap: 0;
    width: 45%;
  }
  .info .button {
    font-size: 0.9rem;
    flex: 1;
    width: 100%;
  }

  /* tabela */
  .table-container {
    padding-inline: 1rem;
    max-width: 100vw;
    overflow-x: auto;
  }
  .table {
    width: auto;
  }

  .table-header {
    & th {
      text-align: center;
    }
  }

  .table-row {
    width: 100%;
  }

  .action-scroll button {
    min-width: 115px;
    font-size: 12px;
  }
  .date {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}
