.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.permissionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;

  > h3 {
    font-size: 1.125rem;
    font-weight: 600;
  }
}

.permissionsList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.permissionsList li {
  list-style: none;
}

.form-agent {
  width: 80vw;
  max-width: 460px;
  padding: 0 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  > div:has(input) {
    align-items: normal;
  }

  .pass-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: flex-end;
    padding: 0 1.5rem;

    > span {
      font-weight: 500;
    }
    input {
      width: 110px;
      text-align: center;
    }
  }

  .actions {
    display: flex;
    gap: 1rem;

    > button:nth-child(2) {
      flex: 1;
    }
  }
}

.info-box {
  align-self: center;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  text-align: center;
  color: var(--info);
  max-width: 280px;
  span {
    flex: 1;
    font-size: 0.875rem;
  }
}
