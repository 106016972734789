.container {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;

  border-radius: 8px;
  background: #e1e5e9;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.info li {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.info li span {
  color: var(--black);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  line-height: 130%; /* 23.4px */
}

.info li span:first-child {
  font-weight: 700;
}

.values-wrapper {
  display: flex;
  gap: 32px;
}

.quantity {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.quantity span {
  color: var(--black);
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  line-height: normal;
}

.quantity span:first-child {
  font-size: 14px;
  font-weight: 700;
}

.quantity span:last-child {
  color: var(--white);
  font-size: 18px;
  font-weight: 600;

  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--2s-scale-10, 10px);
  border-radius: 999px;
  background: var(--primary);
}

@media screen and (max-width: 480px) {
  .container {
    flex-wrap: wrap;
    gap: 1rem;
  }
}
