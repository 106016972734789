.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;

  & > tr {
    background: var(--white) !important;
  }
  & > th {
    padding: 12px 8px;

    color: var(--b_grey, #263238);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.table-cell {
  padding: 0 0.125rem;

  & > input {
    width: 100%;
    height: 100%;
    border: none;
    padding: 1rem 0.25rem;

    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &:hover > input {
    border: 1px solid var(--green);
  }
  /* &.last-row input {
    border-bottom: solid 1px var(--b_grey);'
  } */
  &.center > input {
    text-align: center;
  }
}

.actions-cell {
  /* text-align: end;
  padding: 0.5rem; */
  position: relative;
}

.scroll {
  padding-bottom: 0;
  flex: 1;
}

.remove-btn {
  all: unset;
  cursor: pointer;

  color: var(--error);
}
.add-row-btn {
  all: unset;
  cursor: pointer;

  align-self: flex-end;
  display: flex;
  align-items: center;
  gap: 8px;

  color: var(--secondary, #0b5226);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &:hover {
    text-shadow: var(--secondary) 0px 0px 16px;
  }
}

.divisor {
  width: 100%;
  align-self: stretch;
  border-radius: 16px;
  background: var(--primary, #007a37);
  margin-block: 0.5rem;
}

@media screen and (max-width: 480px) {
  .table {
    width: 400px;
  }

  .table-row {
    & td {
      text-overflow: unset;
    }
  }

  .remove-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}
