.form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @media screen and (max-width: 468px) {
    width: 80vw;
  }
}

.form {
  align-items: center;
}

.form .row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 1rem;

  @media screen and (max-width: 468px) {
    flex-wrap: wrap;
  }
}

.form .row button {
  flex: 1;
}
