/* Contêiner principal */
.container {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
}

/* Botão que contém a imagem */
.thumbnailButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  outline: none;
}

.thumbnailButton:focus {
  outline: 2px solid #007bff;
  outline-offset: 2px;
}

/* Estilo da imagem dentro do botão */
.thumbnailButton img {
  display: block;
  width: 250px;
  height: 250px;
  object-fit: contain;
  transition: transform 0.2s ease, border-color 0.2s ease;
  border: 2px solid transparent;
}

.thumbnailButton img:hover {
  transform: scale(1.05);
  border-color: #007bff;
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
}

/* Contêiner da imagem no modal */
.imageContainer {
  position: relative;
  cursor: auto;
}

/* Estilo da imagem expandida */
.expandedImage {
  max-width: 90%;
  max-height: 80%;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out;
  /* Suaviza o zoom */
  z-index: 2;
}

/* Controles de zoom e resetar */
.controls {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  z-index: 3;
}

.controls button {
  background: #28a745;
  /* Fundo verde */
  color: white;
  border: 2px solid white;
  /* Borda branca para contraste */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  /* Sombra para destaque */
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  /* Texto mais visível */
  cursor: pointer;
  transition: transform 0.2s ease, background 0.2s ease-in-out, box-shadow 0.2s ease;
  z-index: 4;
}

.controls button:hover {
  background: #218838;
  transform: scale(1.1);
  /* Leve aumento no hover */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.6);
  /* Sombra mais intensa */
}

.controls button:active {
  background: #1e7e34;
  transform: scale(1);
  /* Reduz o tamanho ao pressionar */
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.4);
}

/* Botão de fechar */
.closeButton {
  background: #dc3545;
  /* Fundo vermelho */
  color: white;
  border: 2px solid white;
  /* Borda branca */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  /* Sombra para destaque */
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.2s ease-in-out, box-shadow 0.2s ease;
}

.closeButton:hover {
  background: #c82333;
  transform: scale(1.1);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.6);
  /* Sombra mais intensa */
}

.closeButton:active {
  background: #bd2130;
  transform: scale(1);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.4);
}

/* Responsividade */
@media (max-width: 768px) {
  .thumbnailButton img {
    width: 200px;
    height: 200px;
  }

  .expandedImage {
    max-width: 100%;
    max-height: 70%;
  }

  .controls button {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }

  .closeButton {
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
  }
}