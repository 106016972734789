.container {
  border-radius: 999px;
  width: 28px;
  height: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.income {
  background-color: #c8e6c9;
  color: var(--primary);
}

.expense {
  background-color: #fce4e4;
  color: var(--error_text);
}
