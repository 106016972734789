.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  inset: 0;

  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.container {
  position: fixed;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-width: 260px;

  padding: 1.5rem;
  border-radius: 8px;
  background-color: white;

  color: black;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  display: flex;
  flex-direction: column;
  align-items: center;

  max-height: 94vh;
  &.full {
    max-height: 100vh;
    padding-top: 0;
  }
  overflow-y: auto;
  &.border {
    border-top: 8px solid var(--primary);
  }

  .logo {
    width: 42px;
    height: auto;

    position: absolute;
    left: 10px;
    top: 10px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.close {
  background: none;
  border: none;
  font-size: 1rem;
  color: black;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;

  position: absolute;
  top: 10px;
  right: 10px;

  cursor: pointer;
  border: 1px solid #263238;
  border-radius: 2px;

  transition: 40ms;
}
.close:hover {
  transform: scale(1.04);
  transition: 40ms;
  background-color: #2632381e;
}

.title {
  color: var(--Primary, #007a37);
  font-size: 2.375rem;
  text-align: center;

  font-family: 'Bebas Neue', sans-serif;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@media screen and (max-width: 600px) {
  .logo {
    position: absolute;
    left: 8px;
    top: 8px;

    width: 48px;
    height: 48px;
  }

  .container {
    width: 94vw;

    &.full {
      width: 100vw;
    }
  }
}
