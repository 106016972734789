.container {
  display: flex;
  width: 100%;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.actions button {
  all: unset;
  cursor: pointer;

  display: flex;
  width: 150px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--secondary);

  color: var(--white);
  text-align: center;
  font-family: 'Bebas Neue';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

button.confirm-btn {
  background: var(--white);
  border: 2px solid var(--secondary);
  color: var(--secondary);
}
