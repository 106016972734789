.images-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.images-wrapper img {
  width: 350px;
  object-fit: cover;
  max-width: 300px;
  max-height: 300px;
}
