.container {
  background: var(--silver);
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
}
.container:hover {
  filter: brightness(0.95);
  transition: all ease-in 0.35s;
}

.container.answer-quotation {
  border-left: 0.5rem solid #43a046;
  padding: 1.5rem 0.5rem;
}

.container.quotation {
  border-left: 0.5rem solid var(--info);
  padding: 1.5rem 0.5rem;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
}
.date-time {
  text-align: end;
  color: #89939e;
  font-family: 'Arial';
}
.icon-wrapper {
  padding: 0 1rem;
}

.notification-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}
.title {
  font-weight: 700;
  font-size: 1rem;
  text-transform: capitalize;
}
