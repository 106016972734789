.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin: auto;
  gap: 0.5rem;
}

.step {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--grey);
}

.step hr {
  height: 0.35rem;
  background-color: var(--grey);
  border: none;
  border-radius: 4px;
}

.selected .title {
  color: var(--primary);
}

.selected hr {
  background-color: var(--primary);
}
