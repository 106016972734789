.image-input-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  overflow-x: auto;
  padding-inline: 1rem;
  padding-block: 0.25rem;
  width: 100vw;
}

.image-input-container::-webkit-scrollbar {
  height: 8px;
}

.image-input-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.image-input-container::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.image-input-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.input-image-control {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-image-control label {
  font-size: 1.2rem;
  font-weight: 600;
}

.input-image-control input {
  display: none;
}

.input-image-control .img-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.5rem;
  border-radius: 8px;
  height: 2.5rem;
  background: var(--silver);
  font-size: 0.8rem;
  gap: 0.5rem;
}

.add-image-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--primary);
  border-radius: 4px;

  & > button {
    all: unset;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary);

    width: 100px;
    height: 100px;
  }
}

.image-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.image-wrapper img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 4px;
}
.image-wrapper img.pending {
  opacity: 0.5;
  filter: grayscale(100%);
}

.image-wrapper:hover img.only-view {
  cursor: pointer;
  transform: scale(1.1);
  transition: transform ease-in 0.2s;
}

.image-container {
  position: relative;
}

.modal-container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.image-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.transform-component {
  width: 100%;
  justify-content: center;
  padding: 1rem;
  height: 100vh;
  width: 100vw;
}

.selected-image {
  min-width: 400px;
  max-width: 90vw;
  max-height: 80vh;
  object-fit: contain;
  border: 3px solid var(--silver);
}

.tools-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  z-index: 10;
  width: 100%;
  justify-content: flex-end;
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 20px;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex: 1;
}

.zoom-buttons {
  display: flex;
  gap: 1rem;
  align-items: center;

  position: absolute;
  z-index: 10;
}

.remove-button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;

  background: var(--error);
  color: var(--white);
  border: none;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  color: var(--white, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media only screen and (max-width: 480px) {
  .image-input-container {
    width: 100%;
  }
  .selected-image {
    min-width: 200px;
  }

  .tools-container {
    bottom: 5rem;
    right: 2rem;
  }

  .add-image-button button {
    width: 50px;
    height: 50px;
  }
}
