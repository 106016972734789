.searchOptions {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.searchOptions .option label {
  cursor: pointer;
  display: flex;
  min-width: 140px;
  padding: 0.25rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  border-radius: 12px;
  background: #89939e;

  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  line-height: 18px; /* 90% */
}
.searchOptions .option input:checked + label {
  background: var(--primary);
}

.searchOptions input {
  display: none;
}

@media only screen and (max-width: 480px) {
  .searchOptions .option label {
    font-size: 14px;
    min-width: 85px;
  }
}
