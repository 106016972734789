.auto {
  @media screen and (max-width: 870px) {
    display: grid;
    width: 100%;
    gap: 0.5rem;

    tr {
      border-radius: 8px;

      display: grid;
      /* margin: 0.5rem; */
      grid-template-columns: 0.2fr 1.9fr 0.9fr;
      grid-template-rows: 1fr 0.6fr 0.6fr 0.6fr 0.6fr;

      grid-template-areas:
        'icon title value'
        'situation situation situation'
        'created created created'
        'validade validade validade'
        'discharge discharge discharge';
    }

    tr:nth-of-type(2n) {
      padding: 1rem;
      border: 0.01px solid #89939e;
    }

    tr td:nth-child(1) {
      grid-area: icon;
    }
    tr td:nth-child(2) {
      grid-area: title;
      font-size: 1.25rem;
      align-content: center;
    }

    tr td:nth-child(3) {
      grid-area: created;
    }

    tr td:nth-child(4) {
      grid-area: validade;
    }

    tr td:nth-child(5) {
      grid-area: discharge;
    }

    tr td:nth-child(6) {
      grid-area: situation;
    }
    tr td:nth-child(7) {
      grid-area: value;
      font-size: 1.25rem;
      align-content: center;
      text-align: end;
    }

    th {
      display: none;
    }
  }
  @media (max-width: 488px) {
    tr {
      grid-template-rows: 1fr 1fr 0.7fr 0.7fr 0.7fr 0.7fr;
      grid-template-areas:
        'title title title'
        'value value value'
        'situation situation situation'
        'created created created'
        'validade validade validade'
        'discharge discharge discharge';
    }

    tr:nth-of-type(2n) td:nth-child(1) {
      display: none;
    }
    tr td:nth-child(7) {
      width: auto;
      text-align: start;
      font-size: 1.5rem;
      padding-top: 0;
    }
    tr td:nth-child(2) {
      width: auto;
      padding-bottom: 0;
    }
  }
}
