.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000040;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  width: 500px;
  height: 400px;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
}
.header {
  display: flex;
  background: #6bb56a;
  color: #fff;
  width: 100%;
  justify-content: space-between;
  padding: 0.5rem 0.8rem;
}
.header h2 {
  text-align: center;
  flex: 1;
}
.header button {
  all: unset;
  cursor: pointer;
}
.body {
  flex: 1 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .content {
    width: 90%;
  }
}

@media screen and (max-width: 880px) {
  .content {
    width: 90%;
  }
}
