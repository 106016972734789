.vehicle-info {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
  gap: 1rem;
  align-self: normal;
}
.vehicle-info input {
  font-weight: 700;
}

.row {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
}
.row:first-child,
.row:last-child {
  display: grid;
  grid-template-columns: 1.4fr 0.6fr;
  justify-content: space-between;
}

.payment-select {
  border: 2px solid var(--l_grey);
  border-radius: 8px;
  background-color: var(--silver);
  height: 2.5rem;
  padding: 0.25rem 0.5rem;
  width: 100%;
}
