.closed {
  font-family: 'Bebas Neue';
  font-size: 1.5rem;
  font-style: normal;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: var(--silver);
  cursor: pointer;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background: var(--silver);
  animation: opening-animation 0.24s ease forwards;
  transition: all 0.25s;

  position: relative;
}

.container > div {
  animation: opacity-animation 1s 0.25s ease forwards;
  transition: all 0.25s;
  opacity: 0;
}

.icon {
  position: absolute;
  top: 8px;
  right: 8px;

  cursor: pointer;

  &:hover {
    scale: 1.03;
  }
}

.top-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
}

.vehicle-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 2 0;
}

.vehicle-info-name {
  color: var(--black);
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.vehicle-info-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.vehicle-info-list li {
  list-style: none;
  display: flex;
  padding-left: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.paragraph-highlight {
  font-weight: bold;
}

.vehicle-info-list li span:first-child {
  color: var(--primary);
  font-family: 'Bebas Neue';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.vehicle-info-list li span:last-child {
  color: var(--black);
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.vehicle-not-found {
  display: flex;
  align-items: center;
  gap: 12px;

  color: #65707b;
  font-family: 'Bebas Neue';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.images-wrapper {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}

.top-wrapper .images-wrapper {
  display: flex;
}

.images-wrapper img {
  aspect-ratio: 16 / 9;
  object-fit: contain;
  width: 96px;
  height: 96px;
}
.images-wrapper button {
  all: unset;
  cursor: pointer;
  color: var(--primary);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.observation-wrapper {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
  position: relative;
}

.observation-wrapper span {
  color: var(--primary);
  font-family: 'Bebas Neue';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 4px;
  background-color: transparent;
  /* background: #f5f7fa; */
  position: absolute;
  top: -9px;
  left: 12px;
}

.observation-wrapper-highlight {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: #a5d6a7;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  position: relative;
}

.observation-wrapper-highlight span {
  color: var(--primary);
  font-family: 'Bebas Neue';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 4px;
  background: #f5f7fa;
  position: absolute;
  top: -9px;
  left: 12px;
}

.observation-wrapper-highlight {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
  position: relative;
}

.observation-wrapper-highlight span {
  color: var(--primary);
  font-family: 'Bebas Neue';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 4px;
  background: #f5f7fa;
  position: absolute;
  top: -9px;
  left: 12px;
}

.observation-status-wrapper-finished {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: #e8f5e9;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  position: relative;
}

.observation-status-wrapper-finished span {
  color: var(--primary);
  font-family: 'Bebas Neue';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 4px;
  background: #f5f7fa;
  position: absolute;
  top: -9px;
  left: 12px;
}

.observation-status-wrapper-waiting {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: #ffffcc;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  position: relative;
}

.observation-status-wrapper-waiting span {
  color: var(--primary);
  font-family: 'Bebas Neue';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 4px;
  background: #f5f7fa;
  position: absolute;
  top: -9px;
  left: 12px;
}

.observation-status-wrapper-rejected {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: #ffcbd1;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  position: relative;
}

.observation-status-wrapper-rejected span {
  color: var(--primary);
  font-family: 'Bebas Neue';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 4px;
  background: transparent;
  position: absolute;
  top: -9px;
  left: 12px;
}

@media screen and (max-width: 480px) {
  .images-wrapper {
    display: flex;
  }
  .top-wrapper .images-wrapper {
    display: none;
  }
  .vehicle-info-name {
    font-size: 1.125rem;
  }
  .vehicle-info-list li {
    font-size: 1rem;
  }
}

@keyframes opening-animation {
  0% {
    max-height: 0;
    overflow: hidden;
  }
  100% {
    max-height: 100vh;
  }
}

@keyframes opacity-animation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
