.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem 0;
}
.container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.container input {
  height: 3rem;
  border-radius: 8px;
  border: 1px solid rgb(212, 212, 212);
  padding: 0.85rem 0.65rem;
  margin-bottom: 1rem;
  background: transparent;
  width: 300px;
}

.container button {
  all: unset;
  background: #6bb56a;
  color: #fff;
  padding: 1rem 0;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}
