.transaction-item {
  width: 100%;
  list-style: none;

  display: grid;
  grid-template-columns: 1.5fr repeat(4, 1fr) auto;
  align-items: center;

  padding: 1rem 0.5rem;
  background: #dcf5e8;
  border-radius: 8px;
  height: 80px;
  text-align: center;

  @media only screen and (max-width: 768px) {
    align-self: flex-start;
    min-width: 600px;
    height: 60px;
  }
}

.transaction-item.expense {
  background: #f5dcdc;
}

.left {
  text-align: left;
  max-width: 200px;
  word-break: break-all;
}
