.content {
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
}
.actions {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
