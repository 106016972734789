.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.back {
  all: unset;
  cursor: pointer;

  width: 100%;
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 0.125rem solid var(--B_Grey, #263238);

  color: var(--b_grey, #263238);
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: all 0.24s ease-in;

  &:hover {
    background: #263238;
    color: var(--white);
  }
}

.autoshop-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--2s-scale-10, 0.625rem);
  align-self: stretch;

  & h1 {
    color: var(--Select-filtroCreateCotation-selected, #000);
    font-family: Montserrat;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.autoshop-item {
  cursor: pointer;
  display: flex;
  height: 3rem;
  padding: 0.5rem 1.5rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  background: #f5f7fa;

  transition: all 0.24s ease-in;

  &:hover {
    background: var(--silver);
  }

  & .autoshop {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    color: var(--Select-filtroCreateCotation-selected, #000);
    font-family: Montserrat;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.rejected .autoshop-item {
  /* background: #fef6f6; */

  transition: all 0.24s ease-in;

  &:hover {
    background: var(--silver);
  }
}

.status {
  color: #263238;
  font-weight: 600;
}

.status.rejected {
  color: #e53835;
}

.status.approving {
  color: #007a37;
}
