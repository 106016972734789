.cardQuickTip {
  width: 16rem;
  box-shadow: 0px 8px 15px rgb(171, 190, 209, 0.2);
  border-radius: 9px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: var(--grey);
}

.cardQuickTip > h1 {
  font-size: 1.5rem;
  padding: 0.8rem 0;
  color: var(--d_grey);
}

.cardQuickTip > img {
  width: 4rem;
  align-self: center;
}
