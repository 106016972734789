.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.access-type-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding-block: 1rem;
}
.access-type-container span {
  display: flex;
  align-items: center;
}

.access-type-selector {
  display: flex;
  gap: 1rem;
}

.access-type-selector button {
  all: unset;
  cursor: pointer;

  width: 150px;
  padding: 0.25rem 0.5rem;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--primary) solid 1px;

  color: var(--primary);
  font-weight: 500;
  transition: background ease 0.25s;
}

.access-type-selector button.selected {
  background-color: var(--primary);
  color: var(--white);
  font-weight: 600;
}

.footer {
  display: flex;
  flex-direction: column;
  width: 100%;

  & button {
    flex: 1;
  }
}

@media screen and (max-width: 480px) {
  .access-type-container {
    flex-direction: column;
    padding-top: 1rem;
  }
}
