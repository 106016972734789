.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--white);
  z-index: 9999;
}

.content > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 880px) {
  .content > div {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .content > div:nth-of-type(1) > div {
    width: 100%;
  }
}
