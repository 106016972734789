.search-input-control {
  display: flex;
  width: 90%;
  padding-block: 0.5rem;
  gap: 1rem;
}
.search-input-control.invalid-entry input {
  border-color: #f54b4f;
}
.search-input-control.invalid-entry button {
  background-color: #f54b4f;
}

.input-control {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-block: 0.5rem;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.button-container button img {
  height: 25px;
}

.search-input-control button,
.button-container button {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.5rem;
  background: #6bb56a;
  color: #f5faf5;
  border-radius: 10px;
  height: 100%;
  cursor: pointer;
}
.search-input-control input {
  padding-left: 0.5rem;
  all: unset;
  height: 2.5rem;
}
.input-control input {
  padding-left: 0.5rem;
  all: unset;
  height: 2.5rem;
}
.input-control input:disabled {
  cursor: not-allowed;
}
.input-control span {
  font-size: 1rem;
  font-weight: 500;
  color: #4b4b4b;
  margin-bottom: 1rem;
}

.search-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 2rem;
}
.viewport {
  width: 80%;
  margin: 1rem 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  padding: 1.5rem 3rem;
}
.search-vehicle {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.search-vehicle form {
  width: 90%;
}
.search-vehicle input {
  background-color: #e7faf6bf;
  border: 1px solid #544c4c40;
  border-radius: 4px;
  padding-left: 0.5rem;
}
.vehicle-info {
  display: flex;
  gap: 1rem;
}

.vehicle-info .input-control:nth-child(1) {
  flex: 3;
}
.vehicle-info .input-control:nth-child(2) {
  flex: 1;
}

@media screen and (max-width: 768px) {
  .viewport {
    width: 95%;
    padding: 1rem 0;
  }
  .search-bar {
    width: 90%;
  }
  .search-container {
    justify-content: center;
    flex-wrap: wrap;

    gap: 2rem;
  }
  .search-vehicle {
    align-items: center;
  }
  .search-vehicle .search-input-control {
    width: 100%;
  }
  .search-vehicle,
  .vehicle-form {
    width: 95%;
  }
}

@media screen and (max-width: 980px) {
  .viewport {
    width: 95%;
    padding: 1rem 0;
  }
  .search-bar {
    width: 90%;
  }
  .search-container {
    justify-content: center;
    flex-wrap: wrap;

    gap: 2rem;
  }
  .search-vehicle {
    align-items: center;
  }
  .search-vehicle .search-input-control {
    width: 100%;
  }
  .search-vehicle,
  .vehicle-form {
    width: 95%;
  }
}
