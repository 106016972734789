.container {
  overflow: hidden;
  width: 100%;
}
.header {
  width: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  & > strong {
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.rectangle {
  height: 5px;
  width: 100%;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(
    90deg,
    rgba(217, 217, 217, 0.2) 0%,
    #007a37 50%,
    rgba(217, 217, 217, 0.2) 100%
  );
  border-image-slice: 1;
}

/* Animação do conteúdo */
.content {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
  max-height: 0px;
  background: #f9f9f9;
  padding: 0px 12px;
}

.expanded {
  opacity: 1;
  padding: 12px;
}

.collapsed {
  opacity: 0;
  padding: 0px 12px;
}
