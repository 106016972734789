.comment {
  padding: 1rem 0.5rem;
  display: flex;
  gap: 0.5rem;

  & .redirect-link {
    all: unset;
    cursor: pointer;
    color: var(--primary);
    text-decoration: underline;

    &:hover {
      color: var(--secondary);
    }
  }
}

.summary {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 28px;
  padding-inline: 0.5rem;
  align-self: stretch;
  & > span {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    /* line-height: normal;
    display: flex;
    justify-content: space-between;
    align-items: center; */
  }
}

/* image modal */

/* .images-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

 */

.images-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  padding: 1rem;
}

.images-wrapper img {
  aspect-ratio: 16 / 9;
  object-fit: contain;
  width: 96px;
  height: 96px;
  cursor: pointer;
}
.images-wrapper button {
  all: unset;
  cursor: pointer;
  color: var(--primary);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.modal-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image img {
  max-width: 350px;
  min-width: 100px;
  object-fit: contain;
  aspect-ratio: 16 / 9;
}
