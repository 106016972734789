.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  padding: 1rem;
  align-items: center;
  gap: 1rem;
}
.input-control {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 1rem;
  width: 90%;
}
.input-control span {
  font-weight: 700;
}
.input-control input {
  height: 3rem;
}
.info {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
}

.register {
  all: unset;
  align-self: center;
  background-color: var(--primary);
  color: var(--white);
  border-radius: 8px;
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
}
