.checklist-report {
  display: flex;
  padding: 32px 120px 1px 120px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 18px;

  background: #f6f5fa;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 2rem;
}
/* cabeçalho */
.report-actions {
  /* position: absolute;
  left: 5px;
  top: 32px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & button {
    border-radius: 8px;
    background: var(--primary, #007a37);

    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
}

.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.report-dates {
  display: flex;
  width: 350px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;

  & div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    color: var(--shade-1, #43a046);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  & span {
    color: var(--black);
  }
}

.report-logo {
  width: 16rem;
}

/* conteúdo */

.report-content {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 24px;
  background: #fff;

  min-height: 100vh;
}

.report-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  row-gap: 4px;
  align-self: stretch;
  flex-wrap: wrap;

  & h2 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  & h3 {
    color: var(--shade-1, #43a046);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.report-section {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  & h3 {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    opacity: 0.8;
    background: #e9e9e9;

    color: var(--black, #000);
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.content-row {
  width: 100%;

  display: flex;
  padding: 0px 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  & div {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  & strong {
    font-weight: 600;
  }
}

.no-images {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 500;
  color: var(--black);
}

@media screen and (max-width: 880px) {
  .checklist-report {
    padding-inline: 0;
  }
  .report-header {
    flex-direction: column;
    align-items: flex-start;
    padding-inline: 1rem;
  }
  .report-dates {
    padding-block: 2rem;
    flex-direction: column;
  }
  .content-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .checklist-report {
    padding-inline: 0;
  }
  .report-header {
    flex-direction: column;
    align-items: flex-start;
    padding-inline: 1rem;
  }
  .report-dates {
    padding-block: 2rem;
    flex-direction: column;
  }
  .content-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}
