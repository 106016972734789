.container {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.title {
  align-self: flex-start;
  font-size: 2rem;
}
.info {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem;
}
.info .timer {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: var(--error);
  gap: 0.5rem;
}
.subtitle {
  align-self: flex-start;
  font-size: 1.25rem;
  color: var(--d_grey);
  font-weight: 400;
}

.buttonViewMore {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.5rem;
}
.viewMore {
  display: none !important;
}

.info-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.info-list li {
  list-style: none;
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}
.info-list li:nth-child(1) {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.buttonViewMore {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 700;
}

.actions {
  display: grid;
  /* align-items: stretch; */
  grid-template-columns: 1fr 2fr;
  justify-content: center;
  gap: 1rem;
}
