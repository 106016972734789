.form-delete {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  width: 80vw;
  max-width: 320px;

  p {
    text-align: center;
  }

  .row {
    display: flex;
    gap: 1rem;
    width: 100%;

    button {
      flex: 1;
    }
  }
}
