.container {
  width: 8.75rem;
  height: 5.625rem;
  border-radius: 1.25rem;
  background: #f5faf5;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.container button {
  all: unset;
  background-image: url('../../../../../assets/img/headerCard.svg');
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.icon {
  position: absolute;
  top: 0.3rem;
  left: 0rem;
}
.icon.emphasis::before {
  content: '';
  display: block;
  width: 0.65rem;
  height: 0.65rem;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f54b4f;
  border-radius: 50%;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;

  padding: 0.5rem;
}

.value {
  color: var(--white);
  font-weight: 600;
  font-size: 1.5rem;
}
.label {
  color: var(--white);
  font-size: 0.875rem;
  text-align: center;
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #ffffff44;
  animation: loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loader div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.loader div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.loader div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@media screen and (max-width: 480px) {
  .container {
    width: 115px;
    height: 115px;
    border-radius: 20px;
  }
}
@keyframes loading {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
