.header {
  display: grid;
  grid-template-columns: 50px 2fr 1fr 1fr;
  padding: 3px 20px 3px 12px;
  align-items: center;
  align-self: stretch;

  border-radius: 4px;
  background: var(--tint_3);
  width: 100%;

  text-align: center;
}

.header span {
  color: var(--black);
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 23.4px */
}

.header span:nth-child(2) {
  text-align: center;
}

@media screen and (max-width: 480px) {
  .header span {
    font-size: 1rem;
    text-align: center;
  }
}
