.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 4px solid var(--primary);
  padding: 1rem 4rem;
  position: relative;
  overflow: hidden;
  background: var(--white);

  & img {
    cursor: pointer;
  }
}

.title {
  font-family: 'Bebas Neue';
  color: var(--primary);
  font-size: 3rem;
  position: relative;
  z-index: 2;
}

.logo {
  width: 16rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
}

.pattern {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 400px;
  z-index: 1;
}

.back-btn,
.menu {
  display: none;
  position: absolute;
  top: 1rem;
}

.back-btn {
  left: 1rem;
}
.menu {
  right: 1rem;
  color: var(--primary);
}

@media screen and (max-width: 480px) {
  .header {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  .desktop {
    display: none;
  }
  .title {
    font-size: 2rem;
    text-align: center;
  }
  .pattern {
    width: 300px;
    right: -65px;
  }
  .back-btn,
  .menu {
    display: block;
  }
}

@media screen and (max-width: 880px) {
  .header {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  .desktop {
    display: none;
  }
  .title {
    font-size: 2rem;
    text-align: center;
  }
  .pattern {
    width: 300px;
    right: -65px;
  }
  .back-btn,
  .menu {
    display: block;
  }
}
