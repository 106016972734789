:root {
  --notification-width: 480px;
  --notification-height: 132px;

  --notification-gap: 0.5rem;
  --notification-animation-duration: 0.35s;
}

.container {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--notification-gap);
  width: var(--notification-width);
  min-height: var(--notification-height);

  border-radius: 10px;
  border-top: 4px solid var(--primary);
  background: #fff;

  position: fixed;
  bottom: var(--notification-gap);
  right: calc(var(--notification-width));

  z-index: 1000;
  overflow: hidden;

  &.animate {
    animation: slide-from-left var(--notification-animation-duration) ease
      forwards;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.95;
  }

  & button {
    all: unset;
  }

  & button:hover {
    transform: scale(1.1);
    transition: transform 0.25s ease;
    color: var(--error);
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: var(--notification-gap);
}

.header {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  & img {
    position: absolute;
    z-index: -1;
    top: -7rem;
    right: 0;
  }
}

.title {
  position: relative;
  z-index: 1;
  color: var(--primary);
  text-align: center;
  font-family: 'Bebas Neue';
  font-size: 40px;
  font-weight: 400;
  line-height: normal;
}
.content {
  color: var(--b_Grey, #263238);
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}

@keyframes slide-from-left {
  0% {
    transform: translateX(calc(var(--notification-width) * -1));
  }
  100% {
    transform: translateX(
      calc(var(--notification-gap) + var(--notification-width))
    );
  }
}

@media screen and (max-width: 480px) {
  :root {
    --notification-width: 100%;
  }
}
