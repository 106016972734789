.textarea-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  &.error .textarea-wrapper {
    border: 2px solid var(--error, #e53835);
    background: #fff5f5;
  }
}

.textarea-container label {
  color: #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.textarea-container .textarea-hint {
  color: #5e5f60;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.textarea-wrapper {
  display: flex;
  min-height: 44px;
  padding: 8px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #eef1f6;
  margin-top: 0.5rem;
}
.textarea-wrapper textarea {
  all: unset;
  cursor: pointer;
  width: 100%;

  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  resize: vertical;
  overflow-x: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;

  &::placeholder {
    color: #89939e;
  }
}

.textarea-wrapper:focus-within {
  border: 1px solid var(--l_grey);
}
.textarea-wrapper:has(:disabled) {
  background: #e7e7e7;
  font-weight: 500;
  color: #89939e;
}
.textarea-wrapper .textarea-icon {
  font-size: 24px;
}
