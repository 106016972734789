.info-row {
  padding: 0.5rem 0 0.5rem 0.5rem;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
}
.info-row p {
  max-width: 300px;
  margin-right: 2rem;
}
.info-row .full-line {
  max-width: 100%;
}

.info-row span {
  font-weight: 700;
  margin-right: 0.5rem;
}
