.modal {
  max-width: 680px;
  width: 90vw;
}

@media only screen and (max-width: 480px) {
  .wrapButtonFooter {
    margin-bottom: 3rem;
  }
}
