.container {
  all: unset;
  background: #404040;
  width: auto;
  padding: 1rem;
  color: #fff;
  font-family: Arial;
  display: none;
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}
.container.visible {
  display: inline-block;
}
.container span {
  color: rgb(59, 73, 223);
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .container {
    text-align: center;
    width: 90%;
    font-size: 1.2rem;
  }
}
