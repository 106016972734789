.list {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  & li {
    list-style: none;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 1px solid var(--grey);
    padding: 0.5rem 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  & li:hover {
    background: var(--tint_4);

    cursor: pointer;
  }
}
