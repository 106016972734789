.modal {
  width: 90vw;
  max-width: 680px;
  padding: 2rem;
  padding-top: 1rem;
}

@media only screen and (max-width: 480px) {
  /* .wrapButtonFooter {
    margin-bottom: 3rem;
  } */

  .modal {
    /* width: auto; */
    padding-right: 0;
    padding-left: 0;
  }
}
