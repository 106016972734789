.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 1rem 0;
}
.services-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 250px;
  width: 100%;
  overflow: auto;
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.item {
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.75rem;
  border-radius: 8px;
  width: 90%;
  font-weight: 500;
  color: #1e1e1e;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
}
.trash {
  all: unset;
  cursor: pointer;
}
.container .add-btn {
  all: unset;
  background: #6bb56a;
  color: #fff;
  padding: 1rem 0;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}
