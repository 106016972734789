.subrow {
  background: var(--tint_5);
  padding: 1rem 2rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  backdrop-filter: blur(5px);
}

.subrow button {
  border-radius: 4px;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  font-weight: 600;
}

@media screen and (max-width: 880px) {
  .subrow {
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;
    width: 100%;
    overflow-x: auto;
  }
  .subrow::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }
  .subrow::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #f0f0f0;
  }
  .subrow button {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 480px) {
  .subrow {
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;
    width: 100%;
    overflow-x: auto;
  }
  .subrow::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }
  .subrow::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #f0f0f0;
  }
  .subrow button {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 440px) {
  .subrow button {
    font-size: 0.7rem;
  }
}
