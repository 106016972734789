.container {
  width: 100%;
  padding-top: 2rem;
}

.header {
  width: 95%;
  margin: auto;

  display: grid;
  align-items: center;

  background-color: #e9e9e9;
  color: #1e1e1e;
  font-weight: bold;

  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  padding: 0.75rem;
  border-radius: 8px;
}
.maintenances-list-content {
  width: 95%;

  margin-top: 1rem;

  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  border-radius: 8px;
}
.maintenances-list-content-wrapper {
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}
.empty {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  color: #1e1e1e;
  font-size: 2rem;
}
