.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step {
  all: unset;
  cursor: pointer;

  display: flex;
  padding: 1rem 0px;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex: 1 0 0;

  border-bottom: 3px solid #dbdbdb;

  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  position: relative;

  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out,
    border-bottom-color 0.2s ease-in-out, text-shadow 0.2s ease-in-out;

  &:hover {
    color: var(--secondary);
    text-shadow: 0px 2px 8px rgba(0, 122, 55, 0.4);
  }

  &:hover span {
    transform: scale(1.05);
  }

  &.selected {
    border-bottom-color: var(--primary);
    color: var(--primary);
  }
  & .warning {
    position: absolute;
    top: 0;
    right: 0;

    animation: pulse infinite 1s;
    border-radius: 50%;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(254, 230, 49, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@media screen and (max-width: 480px) {
  .step {
    & span {
      display: none;
    }
  }
}
