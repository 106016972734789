.form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @media screen and (max-width: 468px) {
    width: 80vw;
  }
}

.form input {
  flex: 1;
}

.form .row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @media screen and (max-width: 468px) {
    flex-wrap: wrap;
  }
}

.form .row button {
  flex: 1;
}

.select {
  padding: 0.5rem 1rem;
  border: none;

  border-radius: 8px;
  background: #eef1f6;

  color: var(--black, #000);
  font-size: 1.125rem;
  font-weight: 500;
  border: 1px solid transparent;

  /* reset arrow */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* Create new Arrow */
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #eef1f6, #eef1f6);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.select.default {
  color: gray;
}
