.container {
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.container h1 {
  color: var(--black);
  text-align: center;
  font-family: Montserrat;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.quotation-list-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 0.5rem;
}

.quotation-list-content h3 {
  text-decoration: underline;
  color: var(--primary);
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 480px) {
  .container h1 {
    font-size: 1.75rem;
  }
}
