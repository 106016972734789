.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.new-checklist-button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: var(--primary);
  border-radius: 8px;

  color: #fff;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-weight: 600;
}

.elapsed-time {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  padding: 0.25rem;
}

.content {
  margin-top: 1rem;
  height: 100%;
}

.loading-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (max-width: 468px) {
  .header {
    flex-direction: column-reverse;

    & div {
      width: 100%;
    }
  }

  .new-checklist-button {
    padding-inline: 0;
    width: 100%;
  }
  .input-search {
    width: 100%;
  }
}
