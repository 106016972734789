.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  width: 100%;
  justify-content: space-evenly;
}
.container h3 {
  color: #6bb56a;
  font-size: 1.25rem;
}
.input-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.input-control input {
  all: unset;
  padding: 0.5rem;
  background: #e7faf6bf;
  border: 1px solid #544c4c40;
  text-align: center;
}
/* .input-control input:disabled {
  border: none;
} */
.actions {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 1.5rem;
}
.actions button {
  cursor: pointer;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  box-shadow: 0 4px 4px #00000040;
  border-radius: 8px;
  gap: 0.5rem;
}
.exclude {
  background-color: #f54b4f;
}
.edit {
  background-color: #6bb56a;
}
