.table {
  width: 100%;
  border-collapse: collapse;
}
.table-root {
  position: relative;
}
.table-header {
  position: sticky;
  top: 0;
  z-index: 2;

  & > th {
    padding-left: 1.3rem;
  }
}

.table-content {
  flex: 1;
  overflow-y: auto;
}

.table-row {
  cursor: pointer;

  &.selected {
    background: var(--tint_5, #e8f5e9);
  }
}

.table-row-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row-actions {
  position: relative;
  background-color: var(--slate);
  height: 0px;
  transition: height 120ms;
  background: var(--tint_5, #e8f5e9) !important;
}

.row-actions.collapse {
  height: 68px;
}

.row-actions.collapse td {
  padding: 0.75rem;
  position: relative;
}

.row-actions.collapse div {
  opacity: 1;
  visibility: visible;

  transform: translateY(-50%);
  top: 50%;
  transition: 0;
}

.actions {
  padding: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.actions > div {
  display: flex;
  align-items: center;
  gap: 1rem;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 16px;
  max-width: none;
  transform: translateY(-10px);
  transition: 0s;
}

.button {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: var(--primary, #007a37);
  height: 32px;
  min-width: 140px;
}

.button-delete {
  display: flex;
  padding: 0.1rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 12px;

  background-color: transparent;
  border-radius: 8px;

  border: 2px solid var(--error, #e53835);
  border-radius: 8px;
  height: 36px;
}

.no-checklists {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 500;
  color: #89939e;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 15vh);
  width: 100%;

  .icon {
    font-size: 32px;
  }
}

.first-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
}

.draft-tag {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.125rem;
  color: var(--primary);
  border: 2px solid var(--primary);
  font-weight: 700;
  border-radius: 8px;
  font-size: 0.75rem;
  background-color: var(--white);
}

.table-scroll {
  overflow-x: clip;
}

@media screen and (max-width: 880px) {
  .table-scroll {
    min-height: 400px;
  }

  .no-checklists {
    font-size: 1.5rem;
    .icon {
      display: none;
    }
  }

  .row-actions.collapse div {
    overflow-x: scroll;
    width: 100vw;
    padding-left: 5rem;
  }
}

@media screen and (max-width: 480px) {
  .table-scroll {
    min-height: 400px;
  }

  .no-checklists {
    font-size: 1.5rem;
    .icon {
      display: none;
    }
  }
  .first-cell {
    flex-direction: column-reverse;
  }
  .row-actions.collapse div {
    overflow-x: scroll;
    width: 100vw;
    padding-left: 5rem;
  }

  .button-delete {
    padding: 6px 8px;
    height: auto;
  }

  .button {
    padding: 6px 8px;
    min-width: 100px;
    font-size: 14px;
    height: auto;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
}
