.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: #00000080;
  align-items: flex-start;
  justify-content: center;
  z-index: 999;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30vh;
  justify-content: flex-start;
  gap: 1.5rem;
  border-top: 0.4rem solid #1f6d3c;
  background: #ffff;
  border-radius: 5px;
  padding: 2%;
  position: relative;
}

.content > button {
  all: unset;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  position: absolute;
  top: 8%;
  right: 4.62%;
}

.overlayImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  z-index: 0;
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1.3rem;
  position: relative;
  z-index: 1;
}

.access-type-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
}
.access-type-container span {
  display: flex;
  align-items: center;
}

.access-type-selector {
  display: flex;
  gap: 1rem;
}

.access-type-selector button {
  all: unset;
  cursor: pointer;

  width: 150px;
  padding: 0.25rem 0.5rem;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--primary) solid 1px;

  color: var(--primary);
  font-weight: 500;
  transition: background ease 0.25s;
}

.access-type-selector button.selected {
  background-color: var(--primary);
  color: var(--white);
  font-weight: 600;
}

.title {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.title > img {
  width: 15%;
}

.title > div {
  display: flex;
  justify-content: center;
  width: 100%;
}

.title > div > h1 {
  color: #007a37;
  text-align: center;
  font-size: 2rem;
  font-family: Bebas Neue;
  font-weight: 400;
  max-width: 300px;
  text-transform: uppercase;
}

.header > h2 {
  text-align: center;
  font-size: 0.9rem;
  font-family: inter;
  font-weight: 700;
  text-transform: uppercase;
}

.access-type-text {
  color: #007a37; /* Substitua pelo código de cor desejado */
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.form h2 {
  font-size: 1rem;
  color: #000000;
  font-family: Inter;
  font-weight: 600;
}

.form input {
  box-shadow: 0px 3px 3px 0px #c0c0c0;
  max-width: 100%;
  height: 30px;
  border: 1px solid #89939e;
  border-radius: 5px;
  padding: 0 10px;
  outline: none;
}
.form > input::placeholder {
  font-size: 15px;
  font-family: Inter;
  font-weight: 400;
  text-align: center;
}

.groupButton {
  display: flex;
  width: 100%;
  justify-content: space-around;

  & :disabled {
    cursor: not-allowed;
  }
}

.groupButton > button {
  padding: 0.4rem 0.6rem;
  border-radius: 7.56px;
  border: none;
  font-family: Bebas Neue;
  width: 40%;
  letter-spacing: 1px;
  cursor: pointer;
  background-color: #0b5226;
  color: #ffff;
}

.groupButton > button:hover {
  background-color: #1f6d3c;
}

@media screen and (max-width: 480px) {
  .content {
    width: 85%;
    padding: 6% 2%;
  }
  .title > div {
    padding-right: 13px;
  }
  .title > div > h1 {
    font-size: 1.7rem;
  }
  .header > h2,
  .form h2 {
    font-size: 0.8rem;
  }
  .form > input::placeholder {
    font-size: 13px;
  }

  .access-type-container {
    flex-direction: column;
    padding-top: 1rem;
  }
}

@media screen and (min-width: 1440px) {
  .content {
    width: 476px;
  }
}
