.image-container {
  position: relative;
  display: inline-block;
}

.image {
  object-fit: cover;
  border-radius: 8px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #aaa;
}
