.description-heading {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.list-title {
  font-family: 'Inter';
  margin-bottom: 1rem;
  margin-left: 2rem;
}
