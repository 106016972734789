.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.image-input {
  width: 68px !important;
  height: 68px;
}
