.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  gap: 1rem;
  position: relative;
  padding-top: 2rem;
}

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
}

.title h1 {
  padding: 0;
  font-size: 2.7rem;
  padding: 1.5rem 0;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 6rem;
  align-self: center;
}

.titleSection h2 {
  font-family: Inter;
  font-weight: 600;
  padding: 0.5rem 0;
}

.titleSection p {
  font-family: Inter;
}

.titleSection > ul {
  padding-left: 1.8rem;
}

.titleSection > ul > li > p > h3 {
  font-family: Inter;
  font-size: 1rem;
  display: inline;
  padding: 0;
}

.titleSection > span {
  color: black;
  font-family: Inter;
  font-size: 1rem;
  font-weight: bold;
  display: block;
  margin-top: 3rem;
}

.containerImage {
  width: 100%;
  text-align: center;
  align-content: center;
}

.titleSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 31.25rem;
}

.backgroundPattern1 {
  z-index: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 22rem;
}

.backgroundPattern2 {
  z-index: 0;
  position: absolute;
  top: 38rem;
  left: 0;
  height: 22rem;
}

.image {
  height: 280px;
  position: relative;
  z-index: 10;
}

.lastText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.lastText h2 {
  text-align: center;
  font-family: Inter, sans-serif;
}

.lastText p {
  text-align: center;
  font-family: Inter, sans-serif;
}

.imageGroup {
  width: 100%;
  height: 11.25rem;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  z-index: 100;
}

.imageGroup div {
  background-repeat: no-repeat;
  background-size: container;
  width: 100%;
  height: 100%;
}

.carousel {
  width: 280px;
  height: 280px;
}

.carouselImage {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.list p {
  font-family: Inter, sans-serif;
  display: inline;
}

.list h3 {
  font-family: Inter, sans-serif;
  display: inline;
  color: #717171;
}

/* Dentro do InfoBlock */
.section > article > img {
  max-width: 30rem;
}

/* Dentro do InfoBlock */
.section > article p {
  min-width: 20rem;
}

@media (min-width: 768px) {
  .carousel {
    display: none;
  }
}

@media (max-width: 1000px) {
  .section {
    flex-direction: column;
    justify-content: center;
  }
  .title {
    margin-left: -3rem;
  }
  .backgroundPattern2 {
    width: 200px;
  }
  .backgroundPattern1 {
    width: 200px;
  }
  .imageGroup {
    height: 70px;
  }
  .imageGroup div {
    background-size: cover;
    height: 80%;
  }
  .backgroundPattern1 {
    bottom: -5rem;
  }
  .image {
    height: 12rem;
  }
  .title h1 {
    font-size: 2.5rem;
    padding: 1rem 0 1rem 3.5rem;
  }
  .section {
    gap: 1rem;
  }

  /* Dentro do InfoBlock */
  .section > article {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 380px) {
  .list p {
    font-size: 0.8rem;
  }

  .list h3 {
    font-size: 1rem;
  }
}

@media screen and (min-width: 2000px) {
  .section {
    gap: 2rem;
  }
  .title h1 {
    font-size: 3rem;
    padding-left: 3.5rem;
  }
}
